import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ClientSideStorageService {

  constructor() { }

  checkLocalStorageSupport():boolean{
    if(typeof(Storage)!=="undefined"){
      return true;
    }
    return false;
  }

  get(key:string):any{
    return localStorage.getItem(key);
  }
  set(key :string,value :any):void{
    localStorage.setItem(key,value);
  }

  delete(key :string):void{
    localStorage.removeItem(key);
  }
  private storageKey = 'chatbotData';

  // Save chatbot data to sessionStorage
  setChatbotData(data: any): void {
    sessionStorage.setItem(this.storageKey, JSON.stringify(data));
  }

  // Retrieve chatbot data from sessionStorage
  getChatbotData(): any {
    const data = sessionStorage.getItem(this.storageKey);
    return data ? JSON.parse(data) : null;
  }

  // Check if chatbot data exists in sessionStorage
  hasChatbotData(): boolean {
    return sessionStorage.getItem(this.storageKey) !== null;
  }

  // Clear chatbot data from sessionStorage
  clearChatbotData(): void {
    sessionStorage.removeItem(this.storageKey);
  }
}

