<div class="div col-12">
  <div class="div-2 col-12">
    <div class="div-3 col-12">
      <div class="column col-7">
        <div class="div-4">
          <div class="div-5">

            <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/9498edba-5ab7-4231-9132-36334e2e7c6d?"
              class="img-2" /> <span class="partnerWith">partners with </span> <span class="nationalPO">National Post
              Offices</span>
          </div>
          <div class="div-6 launchTitle" style="font-size:28px">to launch a dedicated '<span>Deals App</span>'</div>
          <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/a34dbae2-821c-49c0-a993-3e2217bad47a?"
            class="img-3" />
          <div class="div-7">Create Deals</div>

          <div class="div-8">
            <!-- Seamlessly create, edit and track your deals from start to -->
            <!-- finish within a single, intuitive interface -->
            Efficiently create, modify, manage and track your promotional deals through our sleek and intuitive
            dashboard/Interface.
          </div>
          <div style="font-size: 12px; margin-top: 4px;"> Corporate Website: <a target="_blank"
              href="https://ship2myid.com/">Ship2Myid.com</a>.
            Ship2MyID is a member of UPU CC (United Nations Organization for Global Post offices)</div>
          <!-- <div class="div-9"> -->
          <!-- <div class="div-10"></div> -->
          <!-- <div class="div-11"></div> -->
          <!-- <div class="div-12"></div> -->
          <!-- </div> -->
        </div>
      </div>
      <div class="column-2 col-3">
        <div class="div-13 form-group">
          <form>
            <!-- <a href="https://merchants.ship2myid.com/#/merchantregister?id=B"><img class="button3d" src="assets/BPLOGO.png"/ style="height: 60px;width: 150px;border-radius: 10px;box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 8px 12px rgba(0, 0, 0, 0.2);"></a> -->
            <!-- <a href="https://merchants.ship2myid.com/#/merchantregister?id=I"><img src="assets/CILOGO.jpeg"/ style="height: 60px;width: 150px;border-radius: 10px;box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 8px 12px rgba(0, 0, 0, 0.2);"></a> -->
            <!--  <button class=" registerNavigation registerButton"><a href="https://merchants.ship2myid.com/#/merchantregister?id=B">Botswana</a></button> -->
            <!-- <button  class="mx-2 registerNavigation registerButton"><a href="https://merchants.ship2myid.com/#/merchantregister?id=Z">Zimbabwe</a></button> -->
            <!-- <button  class="mx-2 registerNavigation registerButton"><a href="https://merchants.ship2myid.com/#/merchantregister?id=I">Ivory Coast</a></button> -->
            <!-- <a class="registerNavigation" routerLink="/register/administatordetails">Register here</a> -->
            <!-- </div> -->

            <!-- </div> -->
            <span style="text-align: center;
    display: block;">Please select country/post to advertise</span>
            <div class="row">
              <div class="countryIcon col ml-3" style="gap:35px">
                <!-- BPLOGO with Botswana -->
                <div style="text-align: center;">
                  <a (click)="navigateToLogin($event,'B')">
                    <img class="button3d" src="../../../assets/images/poso_landing_img.png"
                      style="height: 60px; width: 150px; border-radius: 10px; box-shadow: 0 4px 6px rgb(0 0 0 / 53%), 0 8px 12px rgb(0 0 0 / 42%);">
                  </a>
                  <div style="margin-top: 10px;">BotswanaPost</div> <!-- Country Name -->
                </div>

                <!-- CILOGO with Ivory Coast -->
                <!-- <div style="text-align: center;"> -->
                <!-- <a href="https://merchants.ship2myid.com/#/merchantregister?id=I"> -->
                <!-- <img src="assets/CILOGO.jpeg" style="height: 60px; width: 150px; border-radius: 10px; box-shadow: 0 4px 6px rgb(0 0 0 / 53%), 0 8px 12px rgb(0 0 0 / 42%);"> -->
                <!-- </a> -->
                <!-- <div style="margin-top: 10px;">cote d'ivoire</div>  Country Name -->
                <!-- </div> -->


                <div style="position: relative; text-align: center;">
                  <!-- <a href="https://merchants.ship2myid.com/#/merchantregister?id=I"> -->
                  <img src="../../../assets/images/CILOGO.jpeg" (click)="navigateToLogin($event,'C')"
                    style="height: 60px; width: 150px; border-radius: 10px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 8px 12px rgba(0, 0, 0, 0.2);">
                  <!-- </a>	 -->
                  <!-- Coming Soon Icon (absolute overlay) -->
                  <img src="../../../assets/images/comingsoonb.png" class="coming-soon-icon"
                    style="position: absolute;top: 41px;right: -32px;width: 65px;height: auto;">

                  <div style="margin-top: 10px;">Cote D'Ivoire</div> <!-- Country Name -->
                </div>



                <div style="position: relative; text-align: center;">
                  <!-- <a href="https://merchants.ship2myid.com/#/merchantregister?id=I"> -->
                  <img src="../../../assets/images/zimpost-logo.png" (click)="navigateToLogin($event,'Z')"
                    style="height: 60px; width: 150px; border-radius: 10px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 8px 12px rgba(0, 0, 0, 0.2);">
                  <!-- </a>	 -->
                  <!-- Coming Soon Icon (absolute overlay) -->
                  <img src="../../../assets/images/comingsoonb.png" class="coming-soon-icon"
                    style="position: absolute;top: 41px;right: -32px;width: 65px;height: auto;">

                  <div style="margin-top: 10px;">Zimbabwe</div> <!-- Country Name -->
                </div>
              </div>
              <div class="countryIcon col mt-3" style="gap:35px">




                <div style="position: relative; text-align: center;">
                  <!-- <a href="https://merchants.ship2myid.com/#/merchantregister?id=I"> -->
                  <img src="../../../assets/images/eqpost.png"
                    style="height: 60px; width: 150px; border-radius: 10px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 8px 12px rgba(0, 0, 0, 0.2);">
                  <!-- </a>	 -->
                  <!-- Coming Soon Icon (absolute overlay) -->
                  <!-- <img src="../../../assets/images/comingsoonb.png" class="coming-soon-icon"
                    style="position: absolute;top: 41px;right: -32px;width: 65px;height: auto;"> -->

                  <div style="margin-top: 10px;">Eq Guinea</div> <!-- Country Name -->
                </div>


                <div style="position: relative; text-align: center;">
                  <!-- <a href="https://merchants.ship2myid.com/#/merchantregister?id=I"> -->
                  <img src="../../../assets/images/Senegal.png"
                    style="height: 60px; width: 150px; border-radius: 10px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 8px 12px rgba(0, 0, 0, 0.2);">
                  <!-- </a>	 -->
                  <!-- Coming Soon Icon (absolute overlay) -->
                  <!-- <img src="../../../assets/images/comingsoonb.png" class="coming-soon-icon"
                    style="position: absolute;top: 41px;right: -32px;width: 65px;height: auto;"> -->

                  <div style="margin-top: 10px;">Senegal</div> <!-- Country Name -->
                </div>



                <div style="position: relative; text-align: center;">
                  <!-- <a href="https://merchants.ship2myid.com/#/merchantregister?id=I"> -->
                  <img src="../../../assets/images/Mauritania.png"
                    style="height: 60px; width: 150px; border-radius: 10px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 8px 12px rgba(0, 0, 0, 0.2);">
                  <!-- </a>	 -->
                  <!-- Coming Soon Icon (absolute overlay) -->
                  <!-- <img src="../../../assets/images/comingsoonb.png" class="coming-soon-icon"
                    style="position: absolute;top: 41px;right: -32px;width: 65px;height: auto;"> -->

                  <div style="margin-top: 10px;">Mauritania</div> <!-- Country Name -->
                </div>
              </div>

              <div class="countryIcon col mt-3" style="gap:35px">

                <div style="position: relative; text-align: center;">
                  <!-- <a href="https://merchants.ship2myid.com/#/merchantregister?id=I"> -->
                  <img src="../../../assets/images/Madagascar.png"
                    style="height: 60px; width: 150px; border-radius: 10px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 8px 12px rgba(0, 0, 0, 0.2);">
                  <!-- </a>	 -->
                  <!-- Coming Soon Icon (absolute overlay) -->
                  <!-- <img src="../../../assets/images/comingsoonb.png" class="coming-soon-icon"
                    style="position: absolute;top: 41px;right: -32px;width: 65px;height: auto;"> -->

                  <div style="margin-top: 10px;">Madagascar</div> <!-- Country Name -->
                </div>
              </div>
            

        </div>



        </form>
        <!-- <span class="ml-20">Existing business <a href="#" (click)="navigateToLogin($event)">click here</a> to login</span> -->
      </div>
      <div>
        <span style="font-size: 12px; cursor: pointer; text-decoration: none; color: blue;"
          (click)="navigateTerms()">Terms & Conditions </span>
        <span style="font-size: 12px; cursor: pointer;text-decoration: none; color: blue;" (click)="navigateFaqs()">|
          FAQs</span>
      </div>

    </div>
  </div>

</div>

</div>