<!-- <div class="div">
    <div class="div-6">
      <div class="div-7">
        <div class="column-2"> -->
<form [formGroup]="detailedReportForm" class="">
  <div class="div-24 mt-2">

    <!-- <div class="div-27">
      <div class="div-28">Report :</div>
      <div class="div-29">
        <input type="text" style="background-color: transparent;" formControlName="campaignName" [readonly]="true" />
      </div>
    </div> -->
    <div class="div-25 bg-soft-warning w-100 pt-3  d-flex justify-content-between align-items-center">
      <a routerLink="/reports" class="div-26">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em" fill="rgba(135,138,155,1)">
          <path
            d="M12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2ZM12 20C16.42 20 20 16.42 20 12C20 7.58 16.42 4 12 4C7.58 4 4 7.58 4 12C4 16.42 7.58 20 12 20ZM12 11H16V13H12V16L8 12L12 8V11Z">
          </path>
        </svg>
        Back</a>
      <!-- <span class="download_pdf" (click)="makePdf()">Download Pdf</span> -->

      <a href="javascript:void(0);" class="btn btn-soft-info" (click)="makePdf()" [class.disabled]="isGeneratingPdf">
        <span>
          <div *ngIf="isGeneratingPdf" class="spinner-border spinner-border-sm me-2" role="status">
          </div> {{placeHolders['LBL_EXPORT_PDF']}}
        </span>

      </a>

    </div>

    <div class="bg-color" #content>


      <div class="row">
        <div class="col-lg-12">
          <div class="card mt-n4 mx-n4">
            <div class="bg-soft-warning">
              <div class="card-body px-4 pb-4 pb-lg-5 pt-4">
                <div class="row mb-3">
                  <div class="col-md">
                    <div class="row align-items-center g-3">


                      <div class="col-md-auto mb-3">
                        <div class="avatar-md">
                          <img [src]="getImageUrl()" [alt]="this.name" width="100px" class="mb-lg-0 mb-3">
                        </div>
                      </div>
                      <div class="col-md">
                        <div class="ms-lg-4 ms-0">
                          <h4 class="fw-bold">{{ this.name }}</h4>
                          <h6>{{ this.description }}</h6>
                          <div class="hstack gap-3 flex-wrap">
                            <div>{{placeHolders['LBL_START_DATE']}}: {{this.start_date}}</div>
                            <div class="vr"></div>
                            <div>{{placeHolders['LBL_END_DATE']}}: {{this.end_date}}</div>
                            <div class="vr"></div>
                            <span class="badge text-uppercase" [ngClass]="{
                                                                  'bg-success-subtle text-success': this.status === 'ACTIVE',
                                                                  'bg-danger-subtle text-danger': this.status !== 'ACTIVE'
                                                                }">
                              {{ this.status }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <!-- end card body -->
            </div>
          </div>
          <!-- end card -->
        </div>
        <!--end col-->
      </div>


      <div class="row event_row">
        <div class="col-xl-4 col-md-4 col-12">
          <div class="card">
            <div class="card-body">
              <div class="px-2 py-2 ">
                <h5 class="text-center">{{placeHolders['LBL_BUDGET']}}</h5>
                <h4>$ {{this.budget}}</h4>
                <div class="progress mt-4" style="height: 6px;">
                  <div class="progress-bar  bg-sucess" role="progressbar" [style.width.%]="budget_percentage"></div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="col-xl-4 col-md-4 col-12">
          <div class="card">
            <div class="card-body ">
              <div class="px-2 py-2 ">
                <h5 class="text-center">{{placeHolders['LBL_MONEY_SPENT']}}</h5>
                <h4>$ {{this.spent}}</h4>
                <div class="progress mt-4" style="height: 6px;">
                  <div class="progress-bar  bg-sucess" role="progressbar" [style.width.%]="spent_percentage"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-md-4 col-12">
          <div class="card">
            <div class="card-body ">
              <div class="px-2 py-2 ">
                <h5 class="text-center">{{placeHolders['LBL_CTR']}}</h5>
                <h4>{{ctr}}</h4>
                <div class="progress mt-4" style="height: 6px;">
                  <div class="progress-bar bg-sucess" role="progressbar" [style.width.%]="ctr_progress"></div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
      <div class="row mx-lg-0 mx-2  mb-lg-4 mb-4 ">
        <div class="col-xl-3 col-md-6 mt-4">
          <div class="card ">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div class="flex-grow-1 ">
                  <p class="text-uppercase fw-medium text-muted  mb-0">{{placeHolders['LBL_IMPRESSIONS']}}</p>
                </div>


              </div>
              <div class="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 class="fs-22 fw-semibold ff-secondary mb-4">{{this.impression}}</h4>

                </div>
                <div class="flex-shrink-0">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="3em" height="3em"
                    class="text-muted " fill="rgba(135,138,155,1)">
                    <path
                      d="M12.0003 3C17.3924 3 21.8784 6.87976 22.8189 12C21.8784 17.1202 17.3924 21 12.0003 21C6.60812 21 2.12215 17.1202 1.18164 12C2.12215 6.87976 6.60812 3 12.0003 3ZM12.0003 19C16.2359 19 19.8603 16.052 20.7777 12C19.8603 7.94803 16.2359 5 12.0003 5C7.7646 5 4.14022 7.94803 3.22278 12C4.14022 16.052 7.7646 19 12.0003 19ZM12.0003 16.5C9.51498 16.5 7.50026 14.4853 7.50026 12C7.50026 9.51472 9.51498 7.5 12.0003 7.5C14.4855 7.5 16.5003 9.51472 16.5003 12C16.5003 14.4853 14.4855 16.5 12.0003 16.5ZM12.0003 14.5C13.381 14.5 14.5003 13.3807 14.5003 12C14.5003 10.6193 13.381 9.5 12.0003 9.5C10.6196 9.5 9.50026 10.6193 9.50026 12C9.50026 13.3807 10.6196 14.5 12.0003 14.5Z">
                    </path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6  mt-4">
          <div class="card ">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div class="flex-grow-1 ">
                  <p class="text-uppercase fw-medium text-muted  mb-0">{{placeHolders['LBL_REACH']}}</p>
                </div>


              </div>
              <div class="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 class="fs-22 fw-semibold ff-secondary mb-4">{{this.reach}}</h4>

                </div>
                <div class=" flex-shrink-0">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="3em" height="3em"
                    class="text-muted " fill="rgba(135,138,155,1)">
                    <path
                      d="M5 3V19H21V21H3V3H5ZM20.2929 6.29289L21.7071 7.70711L16 13.4142L13 10.415L8.70711 14.7071L7.29289 13.2929L13 7.58579L16 10.585L20.2929 6.29289Z">
                    </path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6   mt-4">
          <div class="card ">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div class="flex-grow-1 ">
                  <p class="text-uppercase fw-medium text-muted  mb-0">{{placeHolders['LBL_CLICKS']}}</p>
                </div>

              </div>
              <div class="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 class="fs-22 fw-semibold ff-secondary mb-4">{{deal_click}}</h4>

                </div>
                <div class="flex-shrink-0">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="3em" height="3em"
                    class="text-muted " fill="rgba(135,138,155,1)">
                    <path
                      d="M11.1411 4C9.5587 4 8.75361 4.16876 8.01266 4.56502C7.38342 4.90155 6.90155 5.38342 6.56502 6.01266C6.16876 6.75361 6 7.5587 6 9.14111V14.8589C6 16.4413 6.16876 17.2464 6.56502 17.9873C6.90155 18.6166 7.38342 19.0985 8.01266 19.435C8.75361 19.8312 9.5587 20 11.1411 20H12.8589C14.4413 20 15.2464 19.8312 15.9873 19.435C16.6166 19.0985 17.0985 18.6166 17.435 17.9873C17.8312 17.2464 18 16.4413 18 14.8589V9.14111C18 7.5587 17.8312 6.75361 17.435 6.01266C17.0985 5.38342 16.6166 4.90155 15.9873 4.56502C15.2464 4.16876 14.4413 4 12.8589 4H11.1411ZM11.1411 2H12.8589C14.8728 2 15.9528 2.27848 16.9305 2.8014C17.9083 3.32432 18.6757 4.09169 19.1986 5.06946C19.7215 6.04724 20 7.12721 20 9.14111V14.8589C20 16.8728 19.7215 17.9528 19.1986 18.9305C18.6757 19.9083 17.9083 20.6757 16.9305 21.1986C15.9528 21.7215 14.8728 22 12.8589 22H11.1411C9.12721 22 8.04724 21.7215 7.06946 21.1986C6.09169 20.6757 5.32432 19.9083 4.8014 18.9305C4.27848 17.9528 4 16.8728 4 14.8589V9.14111C4 7.12721 4.27848 6.04724 4.8014 5.06946C5.32432 4.09169 6.09169 3.32432 7.06946 2.8014C8.04724 2.27848 9.12721 2 11.1411 2ZM11 6H13V11H11V6Z">
                    </path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6   mt-4">
          <div class="card ">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div class="flex-grow-1 ">
                  <p class="text-uppercase fw-medium text-muted  mb-0">{{placeHolders['LBL_FAVORITE']}}</p>
                </div>

              </div>
              <div class="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 class="fs-22 fw-semibold ff-secondary mb-4">{{favorites}}</h4>

                </div>
                <div class="flex-shrink-0">                 

                  <svg xmlns="http://www.w3.org/2000/svg" width="3em" height="3em" viewBox="0 0 24 24" fill="rgba(135,138,155,1)" ><path  d="M5.911 6.964c1.524-1.6 3.697-1.55 5.189-.253a1.375 1.375 0 0 0 1.796 0c1.491-1.298 3.666-1.346 5.188.253c.887 1.133 1.196 2.178 1.164 3.137c-.033.982-.426 1.958-1.086 2.922c-1.335 1.953-3.624 3.67-5.557 5.033a1.05 1.05 0 0 1-1.214 0c-1.919-1.356-4.208-3.074-5.547-5.028c-.661-.966-1.057-1.944-1.091-2.926c-.035-.96.272-2.006 1.158-3.138m6.087-1.458C9.933 3.782 6.88 3.74 4.796 5.96l-.022.024l-.02.025c-1.09 1.38-1.55 2.775-1.5 4.146c.048 1.355.59 2.606 1.353 3.72c1.511 2.208 4.02 4.065 5.918 5.406c.885.625 2.06.625 2.945.001c1.911-1.348 4.422-3.206 5.93-5.412c.762-1.114 1.301-2.364 1.347-3.718c.046-1.37-.416-2.763-1.506-4.143l-.02-.025l-.022-.024c-2.083-2.22-5.138-2.178-7.201-.454"/></svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6  mt-4">
          <div class="card ">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div class="flex-grow-1 ">
                  <p class="text-uppercase fw-medium text-muted  mb-0">{{placeHolders['LBL_DEALS_CLAIMED']}}</p>
                </div>


              </div>
              <div class="d-flex align-items-end justify-content-between mt-4">
                <div>

                  <h4 class="fs-22 fw-semibold ff-secondary mb-4">{{ deal_claimed }}</h4>

                </div>
                <div class=" flex-shrink-0">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="3em" height="3em"
                    fill="rgba(135,138,155,1)">
                    <path
                      d="M2.00488 9.49979V3.99979C2.00488 3.4475 2.4526 2.99979 3.00488 2.99979H21.0049C21.5572 2.99979 22.0049 3.4475 22.0049 3.99979V9.49979C20.6242 9.49979 19.5049 10.6191 19.5049 11.9998C19.5049 13.3805 20.6242 14.4998 22.0049 14.4998V19.9998C22.0049 20.5521 21.5572 20.9998 21.0049 20.9998H3.00488C2.4526 20.9998 2.00488 20.5521 2.00488 19.9998V14.4998C3.38559 14.4998 4.50488 13.3805 4.50488 11.9998C4.50488 10.6191 3.38559 9.49979 2.00488 9.49979ZM4.00488 7.96755C5.4866 8.7039 6.50488 10.2329 6.50488 11.9998C6.50488 13.7666 5.4866 15.2957 4.00488 16.032V18.9998H20.0049V16.032C18.5232 15.2957 17.5049 13.7666 17.5049 11.9998C17.5049 10.2329 18.5232 8.7039 20.0049 7.96755V4.99979H4.00488V7.96755ZM9.00488 8.99979H15.0049V10.9998H9.00488V8.99979ZM9.00488 12.9998H15.0049V14.9998H9.00488V12.9998Z">
                    </path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6  mt-4">

          <div class="card ">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div class="flex-grow-1 ">
                  <p class="text-uppercase fw-medium text-muted  mb-0">{{placeHolders['LBL_WEB_VIEW']}}</p>
                </div>


              </div>
              <div class="d-flex align-items-end justify-content-between mt-4">
                <div>

                  <h4 class="fs-22 fw-semibold ff-secondary mb-4">{{ web_view }}</h4>

                </div>
                <div class=" flex-shrink-0">


                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="3em" height="3em"
                    fill="rgba(135,138,155,1)">
                    <path
                      d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM9.71002 19.6674C8.74743 17.6259 8.15732 15.3742 8.02731 13H4.06189C4.458 16.1765 6.71639 18.7747 9.71002 19.6674ZM10.0307 13C10.1811 15.4388 10.8778 17.7297 12 19.752C13.1222 17.7297 13.8189 15.4388 13.9693 13H10.0307ZM19.9381 13H15.9727C15.8427 15.3742 15.2526 17.6259 14.29 19.6674C17.2836 18.7747 19.542 16.1765 19.9381 13ZM4.06189 11H8.02731C8.15732 8.62577 8.74743 6.37407 9.71002 4.33256C6.71639 5.22533 4.458 7.8235 4.06189 11ZM10.0307 11H13.9693C13.8189 8.56122 13.1222 6.27025 12 4.24799C10.8778 6.27025 10.1811 8.56122 10.0307 11ZM14.29 4.33256C15.2526 6.37407 15.8427 8.62577 15.9727 11H19.9381C19.542 7.8235 17.2836 5.22533 14.29 4.33256Z">
                    </path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6  mt-4">
          <div class="card ">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div class="flex-grow-1 ">
                  <p class="text-uppercase fw-medium text-muted  mb-0">{{placeHolders['LBL_MESSAGING']}}</p>
                </div>



              </div>
              <div class="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 class="fs-22 fw-semibold ff-secondary mb-4">0</h4>

                </div>
                <div class="flex-shrink-0">                

                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="3em" height="3em"
                    fill="rgba(135,138,155,1)">
                    <path
                      d="M6.45455 19L2 22.5V4C2 3.44772 2.44772 3 3 3H21C21.5523 3 22 3.44772 22 4V18C22 18.5523 21.5523 19 21 19H6.45455ZM5.76282 17H20V5H4V18.3851L5.76282 17ZM11 10H13V12H11V10ZM7 10H9V12H7V10ZM15 10H17V12H15V10Z">
                    </path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
    <div class="row mx-lg-0 mx-2 bg-color  mt-lg-3 mb-lg-5 mt-3 mb-5" id="eventChart" #eventChart>
      <div class="col-12">
        <div class="card">
          <div class="card-header bg-transparent py-3  border-0">
            <div class="row align-items-center gy-3">
              <div class="col-sm d-flex justify-content-between align-items-center">
                <h5 class="card-title mb-0">{{placeHolders['LBL_EVENT_TREND']}}</h5>

                <div class="dropdown card-header-dropdown">
                  <a #dropdownButton class="text-reset dropdown-btn text-decoration-none"
                    (click)="toggleDropdownEvent()" aria-haspopup="true" aria-expanded="false">
                    <span class="text-muted fs-6">{{ getSelectedEventsText() }}</span>
                    <span class="ms-1">
                      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M7.41 8.58L12 13.17l4.59-4.59L18 10l-6 6l-6-6z" />
                      </svg>
                    </span>
                  </a>
                  <div #dropdownMenu class="dropdown-menu dropdown-menu-end" [class.show]="dropdownEvent">
                    <div class="dropdown-item">
                      <input type="checkbox" id="reachCheckbox" [checked]="selectedEvents.includes('Reach')"
                        (change)="onEventToggle('Reach')">
                      <label for="reachCheckbox" class="ms-2">Reach</label>
                    </div>
                    <div class="dropdown-item">
                      <input type="checkbox" id="clicksCheckbox" [checked]="selectedEvents.includes('Clicks')"
                        (change)="onEventToggle('Clicks')">
                      <label for="clicksCheckbox" class="ms-2">Clicks</label>
                    </div>
                    <div class="dropdown-item">
                      <input type="checkbox" id="favoritesCheckbox" [checked]="selectedEvents.includes('Favorites')"
                        (change)="onEventToggle('Favorites')">
                      <label for="favoritesCheckbox" class="ms-2">Favorites</label>
                    </div>
                    <div class="dropdown-item">
                      <input type="checkbox" id="dealsClaimedCheckbox"
                        [checked]="selectedEvents.includes('CouponcodeView')" (change)="onEventToggle('CouponcodeView')">
                      <label for="dealsClaimedCheckbox" class="ms-2">Coupon code View</label>
                    </div>
                    <div class="dropdown-item">
                      <input type="checkbox" id="webViewCheckbox" [checked]="selectedEvents.includes('WebView')"
                        (change)="onEventToggle('WebView')">
                      <label for="webViewCheckbox" class="ms-2">Web View</label>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="card-body">


            <div class="w-100">
              <canvas #chartCanvas></canvas>
            </div>
           
          </div>
        </div>
      </div>
    </div>


    <div class="row mx-lg-0 mx-2 bg-color  mt-lg-3 mb-lg-5 mt-3 mb-5" id="chart_gender" #chart_gender>
      <div class="col-lg-12">
        <div class="card" id="orderList">
          <div class="card-header bg-transparent py-3  border-0">
            <div class="row align-items-center gy-3">
              <div class="col-lg-4 col-12 d-flex justify-content-between align-items-center">
                <h5 class="card-title mb-0">{{placeHolders['LBL_GENDER_WISE_TREND']}}</h5>
                <div class="dropdown card-header-dropdown">
                  <a #dropdownButton class="text-reset dropdown-btn text-decoration-none" (click)="toggleDropdown()"
                    aria-haspopup="true" aria-expanded="false">
                    <span class="text-muted fs-6">{{ selectedEvent === 'DealsClaimed' ? 'CouponcodeView' : selectedEvent }}</span>
                    <span class="ms-1"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                        viewBox="0 0 24 24">
                        <path fill="currentColor" d="M7.41 8.58L12 13.17l4.59-4.59L18 10l-6 6l-6-6z" />
                      </svg></span>
                  </a>
                  <div #dropdownMenu class="dropdown-menu dropdown-menu-end" [class.show]="dropdownOpen">
                    <a class="dropdown-item" (click)="selectEvent('Impressions')">Impressions</a>
                    <a class="dropdown-item" (click)="selectEvent('Reach')">Reach</a>
                    <a class="dropdown-item" (click)="selectEvent('Clicks')">Clicks</a>
                    <a class="dropdown-item" (click)="selectEvent('Favorites')">Favorites</a>
                    <a class="dropdown-item" (click)="selectEvent('DealsClaimed')">Coupon code View</a>                   
                    <a class="dropdown-item" (click)="selectEvent('WebView')">Web View</a>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="card-body pt-4 w-100 h-100">
            <div class="row d-flex align-items-center">
              <div class="col-lg-4 col-12 ">               

                <canvas #chartGender></canvas>
              </div>
              <div class=" col-lg-8 col-12 mt-lg-0 mt-3">
                <div class="table-responsive">
                  <table class="table table-bordered">
                    <thead style="text-align: center;">
                      <tr>
                        <th scope="col">Gender</th>
                        <th scope="col">Impressions</th>
                        <th scope="col">Reach</th>
                        <th scope="col">Clicks</th>
                        <th scope="col">Favorites</th>
                        <th scope="col">Coupon code View</th>                     
                        <th scope="col">Web View</th>

                      </tr>
                    </thead>
                    <tbody style="text-align: center;">
                      <tr *ngFor="let data of allGenderData">
                        <th scope="row">{{ data.gender }}</th>
                        <td>{{ data.impressions }}</td>
                        <td>{{ data.reach }}</td>
                        <td>{{ data.clicks }}</td>
                        <td>{{ data.favorites }}</td>
                        <td>{{ data.dealsClaimed }}</td>                      
                        <td>{{ data.webView }}</td>

                      </tr>

                    </tbody>
                  </table>
                </div>
              </div>
            </div>

          </div>


        </div>
      </div>
    </div>

    <div class="row mx-lg-0 mx-2 bg-color  mt-lg-3 mb-lg-5 mt-3 mb-5" id="chart_age" #chart_age>
      <div class="col-lg-12">
        <div class="card" id="orderList">
          <div class="card-header bg-transparent py-3  border-0">
            <div class="row align-items-center gy-3">
              <div class="col-lg-4 col-12 d-flex justify-content-between align-items-center">
                <h5 class="card-title mb-0">{{placeHolders['LBL_AGE_RANGE_REPORT']}}</h5>
                <div class="dropdown card-header-dropdown">
                  <a #dropdownButton class="text-reset dropdown-btn text-decoration-none" (click)="toggleDropdown_()"
                    aria-haspopup="true" aria-expanded="false">
                    <span class="text-muted fs-6">{{ selectedEvent_ === 'DealsClaimed' ? 'CouponcodeView' : selectedEvent_ }}</span>
                    <span class="ms-1"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                        viewBox="0 0 24 24">
                        <path fill="currentColor" d="M7.41 8.58L12 13.17l4.59-4.59L18 10l-6 6l-6-6z" />
                      </svg></span>
                  </a>
                  <div #dropdownMenu class="dropdown-menu dropdown-menu-end" [class.show]="dropdownOpen_">
                    <a class="dropdown-item" (click)="selectEvent_('Impressions')">Impressions</a>
                    <a class="dropdown-item" (click)="selectEvent_('Reach')">Reach</a>
                    <a class="dropdown-item" (click)="selectEvent_('Clicks')">Clicks</a>
                    <a class="dropdown-item" (click)="selectEvent_('Favorites')">Favorites</a>
                    <a class="dropdown-item" (click)="selectEvent_('DealsClaimed')">Coupon code View</a>                   
                    <a class="dropdown-item" (click)="selectEvent_('WebView')">Web View</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body pt-4 w-100 h-100">
            <div class="row d-flex align-items-center">
              <div class="col-lg-4 col-12 ">              

                <canvas #chartAge></canvas>
              </div>
              <div class="col-lg-8 col-12 mt-lg-0 mt-3">
                <div class="table-responsive">
                  <table class="table table-bordered">
                    <thead style="text-align: center;">
                      <tr>
                        <th scope="col">Age Range</th>
                        <th scope="col">Impressions</th>
                        <th scope="col">Reach</th>
                        <th scope="col">Clicks</th>
                        <th scope="col">Favorites</th>
                        <th scope="col">Coupon code View</th>
                    
                        <th scope="col">Web View</th>

                      </tr>
                    </thead>
                    <tbody style="text-align: center;">
                      <tr *ngFor="let data of allAgeData">
                        <th scope="row">{{ data.age }}</th>
                        <td>{{ data.impressions }}</td>
                        <td>{{ data.reach }}</td>
                        <td>{{ data.clicks }}</td>
                        <td>{{ data.favorites }}</td>
                        <td>{{ data.dealsClaimed }}</td>                      
                        <td>{{ data.webView }}</td>

                      </tr>
                    </tbody>
                  </table>
                </div>

              </div>
            </div>

          </div>


        </div>
      </div>
    </div>

  </div>