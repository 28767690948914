import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-previewdealwidget',
  templateUrl: './previewdealwidget.component.html',
  styleUrl: './previewdealwidget.component.scss'
})

export class PreviewdealwidgetComponent {
  placeholders = environment.placeholders as { [key: string]: string };
  dealPreviewImg:any;
  previewFromScreen

  constructor(@Inject(MAT_DIALOG_DATA) public data: {previewFromScreen : boolean },private dialog: MatDialog) { 
    this.previewFromScreen = data.previewFromScreen;
    this.dealPreviewImg = this.placeholders['locale'] === 'en' ?'../../../../assets/images/botswanaimg.png':'../../../../assets/images/botswanafrenchimg.png'
  }

  showImage: boolean = true;

  ngOnInit(): void {
    // Check local storage for "Don't show again" preference
    const dontShowAgain = localStorage.getItem('dontShowImage');
    this.showImage = !dontShowAgain; // Show the image only if preference is not set
  }

  skipForNow(): void {
  
    this.dialog.closeAll() // Close the image display for the current session
  }

  dontShowAgain(): void {
    localStorage.setItem('dontShowImage', 'true'); // Persist preference
    this.dialog.closeAll() // Close the image display
  }


}
