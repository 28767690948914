import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrl: './terms-and-conditions.component.scss'
})
export class TermsAndConditionsComponent {
  countryInitials: any;
  countryName: any;
  constructor(private dialog: MatDialog, private route: ActivatedRoute, private router: Router) {
    this.route.queryParams.subscribe(params => {
      const id = params['id'];
      this.countryInitials = id !== undefined ? id : 'D';
    });

    if (this.countryInitials === 'Z') {
      this.countryName = "Zimbabawe"
    } else if (this.countryInitials === 'B') {
      this.countryName = "Botswana"
    }
    else {
      this.countryName = "India"
    }
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  // navigateFaqs() {
  //   const url = `#${this.router.serializeUrl(this.router.createUrlTree(['/faqs']))}`;
  //   window.open(url, '_blank');
  // }


}
