import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog.service';
import { LoginService } from 'src/app/services/login.service';
import { NotificationAlertService } from 'src/app/services/notification-alert.service';
import { CookieService } from 'ngx-cookie-service';
import { TokenStorage } from 'src/app/token.storage';
import { SessionStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrl: './logout.component.scss'
})
export class LogoutComponent {

  constructor(private router: Router, private service: LoginService, private notifyService: NotificationAlertService,
    private confirmationDialogService: ConfirmationDialogService,private cookieService: CookieService, private token: TokenStorage,
    private storageService: SessionStorageService,) {
    // this.confirmationDialogService
    //   .confirm("Confirmation ", "Are you sure you want to logout? ")
    //   .then((confirmed) => {
    //     if (confirmed) {
          this.service.logoutUser().subscribe(res => {
            this.clearSession();
            // when the user logs out then execute
            window.logoutXelp();
            this.notifyService.showSuccess("Logged Out","Success")
            this.router.navigate(["/"]);

          });
      //   }
      // });
  }

  clearSession() {
    window.logoutXelp();
    this.token.clearStorage();
    localStorage.clear();
    sessionStorage.clear();
  }

}
