<div *ngIf="isChangePassword; else showDefaultTemplate">
  <!-- Change Password Template -->
  <form [formGroup]="changePasswordForm">
    <div class="change-div-6">{{placeHolders['LBL_CHANGE_PASSWORD']}}</div>
    <!-- <div class="change-div-7">
          Passwords must have at least 8 characters and contain at least
          two of the following: uppercase letters, lowercase letters,
          numbers, and symbols.
      </div> -->


    <span class="change-span-11">
      <span class="change-span-12">
        <img loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/1dcb1ef744cae731986e62216d917800a97e7f706c63b43bf5f9cf0db0fea805?"
          class="change-img-13" />
        <a class="change-div-21" style="cursor: pointer;" (click)="toggleChangePassword()">{{placeHolders['LBL_BACK_TO_ADMINSTRATOR']}}</a>
      </span>
      <div class="change-div-35">{{placeHolders['LBL_SET_PASSWORD']}}</div>
      <!-- <div class="div-36">
              Passwords must have at least 8 characters and contain at least
              two of the following: uppercase letters, lowercase letters,
              numbers, and symbols.
          </div> -->
      <div class="change-div-37">
        <div class="change-div-38">
          <span class="change-span-21">
            <label class="asterix">{{placeHolders['LBL_OLD_PASSWORD']}}</label>
            <!-- <img loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/4bbf3f6be6b4a0c4435de1895467800e2ddd5d0433d0ed53d3fd76dd73608aab?"
                      class="img-21" /> -->
          </span>
          <div class="change-div-40">
            <span>
              <input matInput [type]="showOldPass ? 'text' : 'password'" class="col-11 form-control ctrlWidth"
                placeholder="{{placeHolders['LBL_PH_ENTER_OLD_PASSWORD']}}" formControlName="oldpasswd"
                pattern="^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,30}$"
                [ngClass]="{ 'is-invalid': c['oldpasswd'].errors && (c['oldpasswd'].dirty || c['oldpasswd'].touched )  }"
                required />
              @if (c['oldpasswd'].errors) {
              <div class="invalid-feedback">
                @if (c['oldpasswd'].errors['required']) {
                <div>Old password is required</div>
                }
              </div>
              }
            </span>
            <mat-icon matSuffix style="overflow: visible;margin-top: 8px;margin-left: -72px" class="col-1"
              (click)="toggleOldPasswordVisibility()">{{showOldPass?'visibility_off':'visibility'}}</mat-icon>
          </div>
        </div>
      </div>
      <div class="change-div-37">
        <div class="change-div-38">
          <span class="change-span-21">
            <label class="asterix">{{placeHolders['LBL_NEW_PASSWORD']}}</label>
            <!-- <img loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/4bbf3f6be6b4a0c4435de1895467800e2ddd5d0433d0ed53d3fd76dd73608aab?"
                      class="img-21" /> -->
          </span>
          <div class="change-div-40">
            <div class="col-4">
              <input matInput [type]="showCreatePass ? 'text' : 'password'" class="col-11 form-control ctrlWidth"
                placeholder="{{placeHolders['LBL_PH_CREATE_PASSWORD']}}" formControlName="password" 
                pattern="^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,30}$"
                [ngClass]="{ 'is-invalid': c['password'].errors && (c['password'].dirty || c['password'].touched )  }"
                required />
              @if (c['password'].errors) {
              <div class="invalid-feedback">
                @if (c['password'].errors['required']) {
                <div>Password is required</div>
                }
                <!-- @if (c['password'].errors['minlength']) {
                              <div>Password must be at least 8 characters</div>
                              }
                              @if (c['password'].errors['maxlength']) {
                              <div>Password must not exceed 30 characters</div>
                              }
                              @if (c['password'].errors['pattern']) {
                              <div>
                                  Enter correct pattern
                              </div>
                              } -->
              </div>
              }
            </div>
            <mat-icon matSuffix style="overflow: visible;margin-top: 8px;margin-left: -72px" class="col-1"
              (click)="togglePasswordVisibility()">{{showCreatePass?'visibility_off':'visibility'}}</mat-icon>

            <app-password-rules style="width: 90%; margin-left: 50px;" class="col-1"></app-password-rules>
          </div>
        </div>
      </div>
      <div class="change-div-38">
        <span class="change-span-22">
          <label class="asterix">{{placeHolders['LBL_CONFIRM_PASSWORD']}}</label>
        </span>
        <div class="change-div-40">
          <span>
            <input matInput [type]="showConfirmPass ? 'text' : 'password'" class="form-control ctrlWidth"
              placeholder="{{placeHolders['LBL_PH_RE_ENTER_PASSWORD']}}" formControlName="confirmPass" 
              [ngClass]="{ 'is-invalid': !c['password'].errors && c['confirmPass'].errors && (c['confirmPass'].dirty || c['confirmPass'].touched ) }"
              required />
            @if ( c['confirmPass'].errors) {
            <div class="invalid-feedback">
              @if (c['confirmPass'].errors['required']) {
              <div>Confirm password is required</div>
              }
              @if (c['confirmPass'].errors['matching']) {
              <div>Confirm password does not match</div>
              }
            </div>
            }
          </span>
          <mat-icon matSuffix style="overflow: visible;margin-top: 8px;margin-left: -72px" class="col-1"
            (click)="toggleConfirmPasswordVisibility()">{{showConfirmPass?'visibility_off':'visibility'}}</mat-icon>
        </div>
      </div>

      <div class="change-div-44">
        <button class="btn btn-secondary" (click)="resetChangePasword()">{{placeHolders['LBL_BTN_RESET']}}</button>
        <button class="btn change-span-25" [disabled]="changePasswordForm.invalid" (click)="saveChangePassword()">{{placeHolders['LBL_UPDATE_CHANGES']}}</button>
      </div>
    </span>
  </form>
</div>

<ng-template #showDefaultTemplate>
  <!-- Default Template -->


  <div class="div-6">{{placeHolders['LBL_MERCHANT_PROFILE']}}</div>
  <!-- <div class="div-7">
    Please provide your email address to begin the registration
    process. The email you provide will be used as your login
    username.
    <br />
    Creating an account through this form will assign you as the
    Primary Contact for your company.
  </div> -->
  <div class="div-8">
    <div class="div-9" [ngClass]="{ 'nav-active': activeStep === 1 }" (click)="adminClick(1)">
      <div class="div-10" [ngClass]="{ 'nav-color': activeStep === 1 }">{{placeHolders['LBL_BUSINESS_DETAILS']}}</div>
      <div class="div-11" [ngClass]="{ 'nav-color': activeStep === 1 }">Brand/ Retailer/ Businesses/ Government/ Others
      </div>
    </div>
    <div>
      <button style="border: none;" (click)="adminClick(2)" class="button">
        <div class="div-12" [ngClass]="{ 'nav-active': activeStep === 2 }">
          <div class="div-10" [ngClass]="{ 'nav-color': activeStep === 2 }" style="text-align: left;">{{placeHolders['LBL_ADMINISTRATOR_DETAILS']}}</div>
          <div class="div-11" [ngClass]="{ 'nav-color': activeStep === 2 }">{{placeHolders['LBL_ADMIN_DEATILS_OF_BRAND']}}</div>
        </div>
      </button>
    </div>
  </div>
  <div *ngIf="this.uuid" style=" width: 35%;margin-top: 5px;margin-bottom: -15px;color: gray;">Merchant Unique ID : {{uuid}}</div>
  <div *ngIf="activeStep === 1">
    <form [formGroup]="registerForm">
      <div class="div-21">
        <div *ngIf="isAlertActive == 'false'" class="alert alert-danger">
          <span>{{placeHolders['LBL_PLEASE_ENTER_MANDATORY_DETAILS']}}</span>
        </div>
        <div class="div-22">
          <img loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/ca1ec5e2ba436ce29ef7a06cabbcfea3dcbd7654fc719e0572afac86383e6263?"
            class="img-13" />
          <div class="div-23">
            <span class="span-11">
              <div class="div-24">{{placeHolders['LBL_BASIC_INFO']}}</div>
              <span class="span-12">
                <!-- <div class="div-25">Administrator Details</div> -->
                <!-- <a class="div-25" routerLink="/companyProfile/details">Administrator Details</a>
              <img loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/a71039696a181ddbf76fda9dc46203587cfc715c1d3b9885eed485ff2c6a24d1?"
                class="img-14" /> -->
              </span>
            </span>
            <div class="row col-12">
              <div class="col-4 form-group">
                <label class="asterix" for="CompanyName">{{placeHolders['LBL_BUSINESS_NAME']}}</label>
                <input type="text" class="col-12 form-control" formControlName="companyName"
                  placeholder="{{placeHolders['LBL_PH_ENTER_BUSINESS_NAME']}}"
                  [ngClass]="{ 'is-invalid': f['companyName'].errors && (f['companyName'].dirty || f['companyName'].touched )}"
                  required />
                @if ( f['companyName'].errors ) {
                <div class="invalid-feedback">
                  @if (f['companyName'].errors['required']) {
                  <div>{{placeHolders['MSG_ERR_BUSINESSNAME_REQUIRED']}}</div>
                  }
                </div>
                }
              </div>
              <div class="col-4 form-group">
                <label for="companyWebsite">{{placeHolders['LBL_BUSINESS_WEBSITE']}}</label>
                <input type="text" class="col-12 form-control" formControlName="companyWebsite"
                  (keydown)="onKeyDown($event)" placeholder="{{placeHolders['LBL_PH_ENTER_WEBSITE']}}"
                  pattern="^(?:(http(s)?)?(sftp)?(ftp)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$"
                  [ngClass]="{ 'is-invalid': f['companyWebsite'].errors && (f['companyWebsite'].dirty || f['companyWebsite'].touched ) }"
                   />
                @if ( f['companyWebsite'].errors) {
                <div class="invalid-feedback">
                  @if (f['companyWebsite'].errors['required']) {
                  <div>{{placeHolders['MSG_ERR_WEBSITE_REQUIRED']}}</div>
                  }
                </div>
                }
              </div>
            </div>


            <div class="row col-12 pt-3">

              <div class="col-3 form-group" *ngIf="logoName===null && companylogo===null">
                <label for="CompanyLogo" class="asterix">{{placeHolders['LBL_BUSINESS_LOGO']}}</label>
                <input class="form-control" style="width: 240px;" type="file" (change)="uploadImage($event)"
                  formControlName="companyLogo" title="Upload" />
                <small class="form-text text-muted" style="font-size: 14px;">
                  {{placeHolders['LBL_LOGO_SIZE_NOT_EXCEED']}}
                </small>
              </div>
              <div class="col-4 form-group" *ngIf="logoName===null && companylogo!==null">
                <!-- <label for="CompanyLogo" class="asterix">Business logo</label>
                <input class="div-71 form-control" type="text" formControlName="companyLogo" title="Upload" />
                <small class="form-text text-muted" style="font-size: 14px;">
                  Logo should not exceed 2MB in size.
                </small> -->
                <ng-container *ngIf="brandlogo">
                  <label class="asterix" for="CompanyName">{{placeHolders['LBL_BUSINESS_LOGO']}}</label>
                  <br>
                  <img *ngIf="brandlogo" [src]="brandlogo" alt="Preview" style="max-width: 150px; height: auto;">
                  <br>
                </ng-container>
              </div>
              <div class="col-3 form-group" *ngIf="logoName!==null">
                <label for="CompanyLogo" class="asterix">{{placeHolders['LBL_BUSINESS_LOGO']}}</label>
                <!-- <input *ngIf="companylogo !== null" class="div-71 form-control" type="text" formControlName="document" title="Upload" /> -->
                <div matTooltip="{{logoName}}" class="form-control file-name-container" style="width: 240px;"
                  type="text" disabled>{{logoName}}</div>
                <img *ngIf="logoName!==null" src="../../../assets/images/delete-icon.png" style="width: 12px;
            margin-left: 215px;
            margin-top: -60px;cursor: pointer;" (click)="removeImage()" />
              </div>
              <div class="col-1 form-group" *ngIf="companylogo === null && !isImageUploaded">
                <button class="btn span-20 logobuttonUpload" (click)="imageUpload()">{{placeHolders['LBL_BTN_UPLOAD']}} </button>
              </div>
              <div class="col-1 form-group" *ngIf="isImageUploaded">
                <img src="../../../assets/images/tickmark-icon.png"
                  style="width:22px; margin-top: 30px;margin-left: -15px;" />
              </div>




              <!-- <div class="col-4 form-group">
            <label for="CompanyLogo" class="asterix">Company logo</label>
            <input type="text" class="div-71 col-12 form-control" formControlName="companyLogo" />
          </div> -->
              <!-- <div class="col-4 form-group">
            <label for="companyregistration">Company Registration Document(Optional)</label>
            <input class="div-71 form-control" type="file" formControlName="document" title="Upload" />
          </div> -->

              <div class="col-4 form-group" *ngIf="docName ==null">
                <label for="companyregistration">{{placeHolders['LBL_BUSINESS_REG_DOC']}}(optional)</label>
                <input *ngIf="documentName===null" class="form-control" style="width: 240px;" type="file"
                  (change)="uploadDoc($event)" formControlName="document" title="Upload" />
                <!-- <input *ngIf="documentName !== null" class="div-71 form-control" type="text" formControlName="document"
                  title="Upload" /> -->
                <ng-container *ngIf="uploadedDoc && documentName !== null">
                  <!-- <label class="asterix" for="CompanyName">Business logo</label> -->
                  <br>
                  <svg (click)="openLink()" *ngIf="uploadedDoc" xmlns="http://www.w3.org/2000/svg" width="4em"
                    height="4em" viewBox="0 0 24 24">
                    <g fill="none" stroke="black" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                      <path d="M15 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7Z" />
                      <path d="M14 2v4a2 2 0 0 0 2 2h4M9 15l2 2l4-4" />
                    </g>
                  </svg>
                  <!-- <img *ngIf="uploadedDoc" [src]="uploadedDoc" alt="Preview" style="max-width: 200px; height: auto;"> -->
                  <br>
                </ng-container>
                <small *ngIf="documentName===null" class="form-text text-muted" style="font-size: 14px;">
                  {{placeHolders['LBL_DOC_SIZE_SHOULD_NOTEXCEED']}}
                </small>
              </div>
              <div class="col-4 form-group" *ngIf="docName!==null">
                <label for="companyregistration">{{placeHolders['LBL_BUSINESS_REG_DOC']}}(optional)</label>
                <!-- <input *ngIf="documentName !== null" class="div-71 form-control" type="text" formControlName="document" title="Upload" /> -->
                <div matTooltip="{{docName}}" matTooltipPosition="left" class="form-control file-name-container"
                  style="width: 240px;" type="text" disabled>{{docName}}</div>
                <img *ngIf="docName!==null" src="../../../assets/images/delete-icon.png" style="width: 12px;
            margin-left: 215px;
            margin-top: -60px;cursor: pointer;" (click)="removeDoc()" />
              </div>
              <div class="col-1 form-group" style="margin-left: -95px;" *ngIf="documentName === null && !isFileUploaded">
                <button class="btn span-20 docButtonUpload" (click)="documentUpload()">{{placeHolders['LBL_BTN_UPLOAD']}}</button>
              </div>
              <div class="col-1 form-group" *ngIf="isFileUploaded">
                <img src="../../../assets/images/tickmark-icon.png"
                  style="width:22px; margin-top: 27px;margin-left: -84px;" />
              </div>

            </div>

            <div class="row col-12 pt-3">
              <!-- 
            <div class="col-4 form-group">
              <label for="companyregistration">Company Registration Document(Optional)</label>
              <input class="form-control" style="width: 240px;" type="file" (change)="uploadDoc($event)"
                formControlName="document" title="Upload" />
              <small class="form-text text-muted" style="font-size: 10px;">
                JPG, PNG, or GIF format and not exceed 2MB in size.
              </small>
            </div> -->
              <div class="col-4 form-group">
                <label for="registrationId">{{placeHolders['LBL_REGISTRATION_ID']}}</label>
                <input type="text" class="col-12 form-control" formControlName="registrationId"
                  placeholder="{{placeHolders['']}}" />
                <!-- [ngClass]="{ 'is-invalid': f['registrationId'].errors && (f['registrationId'].dirty || f['registrationId'].touched )}" -->
              </div>
              <!-- <div class="col-4 form-group">
                <label for="businessType" class="asterix"> {{placeHolders['LBL_BUSINESS_TYPE']}}</label>
                <mat-select formControlName="businessType" class=" form-control" required>
                  @for (BusinessType of allBusinessTypes; track BusinessType) {
                  <mat-option [value]="BusinessType.business_type_id">{{BusinessType.business_type}}
                  </mat-option>
                  }
                </mat-select>
              </div> -->
              <!-- BUSINESS TYPE COMMETED -->

            </div>

            <!-- <div class="row col-12 pt-3">
              <div class="col-10 form-group">
                <label class="asterix" for="productCategories">{{placeHolders['LBL_PRODUCT_CATEGORIES']}}</label>
                <mat-form-field>
                  <mat-select formControlName="productCategories" class="form-control multi-select" multiple>
                    @for (category of allCategory; track category) {
                    <mat-option [value]="category.category_id">{{category.category_type}}
                    </mat-option>
                    }
                  </mat-select>
                </mat-form-field>

            
              </div>
            </div> -->
    <!-- PRODUCT CATEGORY COMMETED -->

          </div>
        </div>

        <div class="div-52">
          <img loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/ca1ec5e2ba436ce29ef7a06cabbcfea3dcbd7654fc719e0572afac86383e6263?"
            class="img-26" />
          <span class="span-31">
            <div class="div-53">{{placeHolders['LBL_BUSINESS_ADDRESS']}}</div>



            <div class="row col-12 pt-3">
              <div class="col-6 form-group">
                <label class="asterix" for="CompanyName">{{placeHolders['LBL_ADDRESS_LINE']}}</label>
                <textarea class="form-control" style="border:none;" formControlName="addressLine" minlength="5"
                  maxlength="250" placeholder="{{placeHolders['LBL_PH_BUSINESS_ADDRESS']}}"
                  [ngClass]="{ 'is-invalid': f['addressLine'].errors && (f['addressLine'].dirty || f['addressLine'].touched ) }"
                  required>
                </textarea>
                @if (f['addressLine'].errors) {
                <div class="invalid-feedback">
                  @if (f['addressLine'].errors['required']) {
                  <div>{{placeHolders['MSG_ERR_ADDRESS_REQUIRED']}}</div>
                  }
                  @if (f['addressLine'].errors['minlength']) {
                  <div>{{placeHolders['MSG_ERR_MUST_5_CHARS']}}</div>
                  }
                </div>
                }
              </div>

              <div class="col-6 form-group">
                <label for="additionalInfo">{{placeHolders['LBL_ADDITIONAL_INFO']}}</label>
                <textarea class="form-control" style="border:none;" formControlName="additionalInfo"
                  maxlength="250" placeholder="{{placeHolders['LBL_PH_ADDITIONAL_INFO']}}"> </textarea>
              </div>
            </div>
           
            <div class="row col-12 pt-3">
              <div class="col-3 form-group">
                <label class="asterix" for="CompanyName"> {{placeHolders['LBL_COUNTRY']}}</label>
                <!-- <span class="span-37">   -->
                <input type="text" formControlName="merchant_country" class="form-control " />
                <!-- </span> -->
              </div>
              <div class="col-3 form-group">
                <label class="asterix" *ngIf="countryInitials === 'D'" for="countrySelect"> {{placeHolders['LBL_STATE']}}</label>
                <label class="asterix" *ngIf="countryInitials === 'Z'" for="countrySelect">State</label>
                <label class="asterix" *ngIf="countryInitials === 'B'" for="countrySelect">State/District</label>
                <mat-form-field class="col-12" style="margin-top: -16px; margin-left: -15px;">
                  <mat-select formControlName="state" class="form-control col-4 " required>
                    @for (state of stateData; track state) {
                    <mat-option (click)="stateSelectForCity(state.state_code)"
                      [value]="state.state_code">{{state.state_name}}
                    </mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-3 form-group">
                <label class="asterix" *ngIf="countryInitials === 'D'" for="countrySelect"> {{placeHolders['LBL_CITY']}}</label>
                <label class="asterix" *ngIf="countryInitials === 'Z'" for="countrySelect">City</label>
                <label class="asterix" *ngIf="countryInitials === 'B'"
                    for="countrySelect">City/Town/Village</label>
                <mat-form-field class="col-12" style="margin-top: -16px; margin-left: -15px;">
                  <mat-select formControlName="city" class="form-control col-4 " required>
                    @for (city of cityData; track city) {
                    <mat-option (click)="citySelectionForZip(city.zip_code,city.zip_id)"
                      [value]="city.zip_id">{{city.city_name}}
                    </mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-3 form-group" *ngIf="countryInitials === 'D'">
                <label class="" for="ZipCode">Zipcode</label>
                <input type="text" class="form-control" formControlName="zipCode" placeholder="Enter Zipcode" />
              </div>
              <div class="col-3 form-group" *ngIf="countryInitials === 'Z'">
                <label class="" for="ZipCode">Zipcode</label>
                <input type="text" class="form-control" formControlName="zipCode" placeholder="Enter Zipcode" />
              </div>

              <div *ngIf="countryInitials === 'B'" class="col-3 form-group">
                <label for="pobox">PO Box</label>
                <input type="text" formControlName="postbox_number" class="form-control" minlength="3" maxlength="8"
                  placeholder="Postbox number" pattern="^[0-9]*$"
                  [ngClass]="{ 'is-invalid':  f['postbox_number'].errors && (f['postbox_number'].dirty || f['postbox_number'].touched )}">
                <small>(Optional)</small>
                @if ( f['postbox_number'].errors) {
                <div class="invalid-feedback">
                  @if (f['postbox_number'].errors['pattern']) {
                  <div>Numbers only </div>
                  }
                  @if (f['postbox_number'].errors['minlength']) {
                  <div>must be at least 3 characters</div>
                  }
                  @if (f['postbox_number'].errors['maxlength']) {
                  <div>must not exceed 8 characters</div>
                  }
                </div>
                }
              </div>

            </div>
          </span>
        </div>
        <!-- <div class="div-72">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/ca1ec5e2ba436ce29ef7a06cabbcfea3dcbd7654fc719e0572afac86383e6263?"
                    class="img-37"
                  />
                  <span class="span-44">
                    <div class="div-73">Company Presence</div>
                    <span class="span-45">
                      <div class="div-74">Categories</div>
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/a6651221268d26d5f7c2459d3d86471df8c55372891d73df0aac8e167e05b80a?"
                        class="img-38"
                      />
                    </span>
                    <div class="div-75">
                      <div class="div-76">
                        <span class="span-46">
                          <div class="div-77">Category 1</div>
                          <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/57f6728b9abb08ca48a3c5a44dfce8050541257cf72d76f742f37dba5d9c5bc3?"
                            class="img-39"
                          />
                        </span>
                        <span class="span-47">
                          <div class="div-78">Category 2</div>
                          <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/57f6728b9abb08ca48a3c5a44dfce8050541257cf72d76f742f37dba5d9c5bc3?"
                            class="img-40"
                          />
                        </span>
                        <span class="span-48">
                          <div class="div-79">Category 3</div>
                          <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/57f6728b9abb08ca48a3c5a44dfce8050541257cf72d76f742f37dba5d9c5bc3?"
                            class="img-41"
                          />
                        </span>
                        <span class="span-49">
                          <div class="div-80">Category 4</div>
                          <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/57f6728b9abb08ca48a3c5a44dfce8050541257cf72d76f742f37dba5d9c5bc3?"
                            class="img-42"
                          />
                        </span>
                      </div>
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/f231dafc1b3fef336bbad007ade9614aba7f4b8bebd006e84c57ee6d4b1289a7?"
                        class="img-43"
                      />
                    </div>
                  </span>
                </div> -->
        <div class="div-81">
          <!-- <button class="btn btn-secondary span-50" (click)="cancel()">Cancel</button>
      <button class=" btn span-51" (click)="save()">Update Changes</button> -->
          <!-- <button type="button" (click)="cancel()" class="btn btn-secondary"
        style="float: right;margin-right: 5px;">Cancel</button> -->
          <button type="submit" class="btn btn-changes" [disabled]="registerForm.invalid" (click)="save()"
            style="cursor: pointer;float: right;">{{placeHolders['LBL_UPDATE_CHANGES']}} </button>

        </div>
      </div>
    </form>
  </div>
  <!-- Administrator Detail Start Here  -->
  <div *ngIf="activeStep === 2">
    <form [formGroup]="administartiveForm">
      <span class="admin-container">
        <!-- <span class="span-12">
            <img loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/1dcb1ef744cae731986e62216d917800a97e7f706c63b43bf5f9cf0db0fea805?"
                class="img-13" />
            <a class="div-21" routerLink="/companyProfile">Back to Company Details</a>
        </span> -->
        <div class="admin-inner col-7">
          <div class="admin-div-23">
            <span class="admin-span-13">
              <label class=" asterix "> {{placeHolders['LBL_PRIMARY_CONTACT_PERSON']}}</label>
              <!-- <img loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/4bbf3f6be6b4a0c4435de1895467800e2ddd5d0433d0ed53d3fd76dd73608aab?"
                    class="img-14" /> -->
            </span>
            <span class="admin-span-14">
              <!-- (keydown.space)="$event.preventDefault();" -->
              <input type="text" class="form-control" formControlName="primaryContact"
                placeholder="{{placeHolders['LBL_PH_PRIMARY_NAME']}}" pattern="^[A-Za-z ]+$" maxlength="30"
                [ngClass]="{ 'is-invalid': a['primaryContact'].errors && (a['primaryContact'].dirty || a['primaryContact'].touched )}"
                required />
              @if ( a['primaryContact'].errors ) {
              <div class="invalid-feedback">
                @if (a['primaryContact'].errors['required']) {
                <div>{{placeHolders['MSG_ERR_PRIMARY_NAME_REQUIRED']}}</div>
                }
                @if (a['primaryContact'].errors['minlength']) {
                <div>{{placeHolders['MSG_ERR_NAME_2_CHARS_MIN']}}</div>
                }
                @if (a['primaryContact'].errors['maxlength']) {
                <div>{{placeHolders['MSG_ERR_MAX_30_CHARS_NAME']}}</div>
                }
                @if (a['primaryContact'].errors['pattern']) {
                <div>{{placeHolders['MSG_ERR_ONLY_CHARS']}}</div>
                }
              </div>
              }
            </span>
          </div>
          <!-- <div class="div-25">
            <span class="span-15">
                <div class="div-26">Country</div>
                <img loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/4bbf3f6be6b4a0c4435de1895467800e2ddd5d0433d0ed53d3fd76dd73608aab?"
                    class="img-15" />
            </span>
            <span class="span-16">
                <div class="div-27">ITALY</div>
                <img loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/717590e7b5540a948d7067906f8675c9b629ac930b6d7ffdcb0d7579a4380246?"
                    class="img-16" />
            </span>
        </div> -->

       
          <div class="admin-div-29">
            <span class="admin-span-17">
              <label class=" asterix"> {{placeHolders['LBL_EMAIL']}}</label>
              <!-- <img loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/4bbf3f6be6b4a0c4435de1895467800e2ddd5d0433d0ed53d3fd76dd73608aab?"
                class="img-17" /> -->
            </span>
            <span class="admin-span-18">
              <div class="admin-div-31">
                <input class="form-control" type="text" placeholder="{{placeHolders['LBL_PH_ENTER_EMAIL']}}" autocomplete="email"
                  formControlName="email" [readonly]="true"
                  [ngClass]="{ 'is-invalid': a['email'].errors && (a['email'].dirty || a['email'].touched ) }"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required />
                @if ( a['email'].errors) {
                <div class="invalid-feedback">
                  @if (a['email'].errors['required']) {
                  <div>{{placeHolders['MSG_ERR_EMAIL_REQUIRED']}}</div>
                  }
                  @if (a['email'].errors['pattern']) {
                  <div>Invalid email !</div>
                  }
                </div>
                }
              </div>
              <!-- <img loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/042d48b1970056ebc0aefc6a98f2a22627250c74f011516202f3bacde9b9b5a9?"
                class="img-18" /> -->
            </span>
          </div>


        </div>
        <div class="admin-div-28 ">
          <div class="col-3 form-group ml-12" *ngIf="updateVisibility===true">
            <div class="custom-select">
              <label class="asterix" for="countrySelect">{{placeHolders['LBL_COUNTRY_CODE']}}</label>
              <select class="mt-2 form-control" formControlName="code" id="code">
                <option *ngFor="let option of countryData" [value]="option.phone_country_prefix">
                  {{option.phonePrefixWithCountryName}}</option>
              </select>
            </div>
          </div>

          <div class="admin-div-32">
            <span class="admin-span-19">
              <label class=" asterix"> {{placeHolders['LBL_CONTACT_NUMBER']}}</label>
              <!-- <img loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/4bbf3f6be6b4a0c4435de1895467800e2ddd5d0433d0ed53d3fd76dd73608aab?"
                    class="img-19" /> -->
            </span>
            <span class="admin-span-20" *ngIf="updateVisibility===false">
              <div class="admin-div-34">
                <input class="form-control" type="text" placeholder="{{placeHolders['LBL_PH_ENTER_MOBILE']}}" formControlName="phone"
                  pattern="^[0-9]{8,10}$"
                  [ngClass]="{ 'is-invalid': a['phone'].errors && (a['phone'].dirty || a['phone'].touched ) }"
                  required />
                @if ( a['phone'].errors) {
                <div class="invalid-feedback">
                  @if (a['phone'].errors['required']) {
                  <div>{{placeHolders['MSG_ERR_PHONE_REQUIRED']}}</div>
                  }
                  @if (a['phone'].errors['pattern']) {
                  <div>Invalid number !</div>
                  }
                  <!-- @if (f['phone'].errors['pattern']) {
                        <div>Invalid Email!!</div>
                        } -->
                </div>
                }
              </div>


              <!-- <img loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/042d48b1970056ebc0aefc6a98f2a22627250c74f011516202f3bacde9b9b5a9?"
                    class="img-20" /> -->
            </span>


            <span class="admin-span-20" *ngIf="updateVisibility===true">
              <div class="admin-div-34">
                <input class="form-control" type="number" placeholder="{{placeHolders['LBL_PH_ENTER_MOBILE']}}"
                  onkeydown="if(event.key==='.' ||event.key==='+' || event.key==='e' || event.key==='E'){event.preventDefault();}"
                  formControlName="updatedPhone" pattern="^[0-9]{8,10}$"
                  onKeyPress="if(this.value.length==10) return false;"
                  [ngClass]="{ 'is-invalid': a['updatedPhone'].errors && (a['updatedPhone'].dirty || a['updatedPhone'].touched ) }" />
                @if ( a['updatedPhone'].errors) {
                <div class="invalid-feedback">
                  @if (a['updatedPhone'].errors['required']) {
                  <div>{{placeHolders['MSG_ERR_PHONE_REQUIRED']}}</div>
                  }
                  @if (a['updatedPhone'].errors['pattern']) {
                  <div>Invalid number !</div>
                  }
                </div>
                }
              </div>
            </span>
          </div>

          <span style="
            margin-top: 30px;
        ">
            <button *ngIf="updateVisibility===false" class="btn admin-div-1000" (click)="updatePhone()">
              {{placeHolders['BTN_EDIT']}} <img loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/567bd4dd-2799-47b6-976f-c9a71011b766?" class="px-1"
                width="20px" /> </button>
            <button *ngIf="updateVisibility===true" class="btn admin-div-1000" (click)="verifyPhone()">{{placeHolders['BTN_VERIFY_MOBILE']}} 
            </button>
            <button *ngIf="updateVisibility===true" class="mx-1 btn admin-div-1000" (click)="cancelUpdatePhone()"> {{placeHolders['BTN_CANCEL']}}
            </button>
          </span>
        </div>

        <div *ngIf="otpFieldVisible" style="display:flex">
          <div class="admin-div-32">
            <span class="admin-span-19">
              <div class="admin-div-33 "></div>
            </span>
            <span class="admin-span-20">
              <div *ngIf="!resendOtp" class="admin-div-34">
                <div class="">{{placeHolders['LBL_ENTER_OTP']}}</div>
                <ng-otp-input class="otp p-0" (onInputChange)="onOtpChange($event)"
                  [config]="{length:6}"></ng-otp-input>
              </div>

              <div class="pt-1 px-4" style="text-align: left;color: gray;margin-left: -27px;">

                <p *ngIf="displayTimer">{{placeHolders['LBL_EXPIRING_IN']}} <span style="color: red;">{{ display }}</span></p>
                <a *ngIf="resendOtp" style="color:rgb(0, 0, 238);text-decoration: underline;cursor: pointer;"
                  (click)="resendOTP()">{{placeHolders['LBL_RESEND_OTP']}}</a>

                <!-- <div>Expiring in <span id="timer">
                          </span>
                        </div> -->
              </div>

              <!-- <img loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/042d48b1970056ebc0aefc6a98f2a22627250c74f011516202f3bacde9b9b5a9?"
                class="img-20" /> -->
            </span>

          </div>

          <div class=" mx-4" style="
            margin-top: 35px;
            " *ngIf="!resendOtp">
            <button class="admin-div-1000" (click)="submitOtp()">Submit </button>
          </div>
        </div>
        <div class="changePass" (click)="toggleChangePassword()">{{placeHolders['LBL_CHANGE_PASSWORD']}}</div>

        <div class="admin-div-44" *ngIf="updateButtonVisibility===true">
          <!-- <button class="span-24" (click)="reset()">Reset</button> -->
          <button class="btn admin-span-25" [disabled]="!isValidityFormValid()" (click)="saveAdminData()">{{placeHolders['LBL_UPDATE_CHANGES']}}</button>
        </div>
      </span>
    </form>
  </div>
</ng-template>