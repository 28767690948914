import { Component } from '@angular/core';

@Component({
  selector: 'app-tncpage',
  templateUrl: './tncpage.component.html',
  styleUrl: './tncpage.component.scss'
})
export class TncpageComponent {
  countryName: any;
  constructor(){
    this.countryName = "country"
  }
}
