import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FilterReportComponent } from './filter-report/filter-report.component';
import { Router } from '@angular/router';
import { CommonUtilService } from '../../services/common-util.service';
import { ColumnApi, GridApi, GridOptions } from "ag-grid-community";
import * as moment from "moment";
import { ViewComponent } from '../sharedComponent/view/view.component';
import { SwitchToggleComponent } from '../sharedComponent/switch-toggle/switch-toggle.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HardcodedData } from '../hardcoded-data';
import { ReportService } from 'src/app/services/report.service';
import { formatDate } from '@angular/common';
import { DashboardService } from 'src/app/services/dashboard.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrl: './report.component.scss'
})
export class ReportComponent implements OnInit {
  placeholders = environment.placeholders as { [key: string]: string };
  placeHolders:any;
  apiPlaceHolders:any;
  gridOptions = <GridOptions>{};
  gridApi: any;
  gridColumnApi: any;
  frameworkComponents: any;
  columnDefs: any;
  header: any;
  id: any;
  defaultColDef = {};
  paginationPageSize: any;
  currentPage: any;
  isPageChanged: any;
  types = [10, 20, 30];
  paramsSelectRecord = {
    type: "gridReady",
    api: GridApi,
    columnApi: ColumnApi,
  };
  minRowHeight = 25;
  data: any = [];
  loanFilter: any;
  presentDate: any;
  past15Days: any;
  pastMonthDay: any;
  pastWeek: any;
  past3Month: any;
  currentDate: any;
  datePickerInput: any;
  // fromDate: any;
  // toDate: any;
  datePickerVisible = false;
  reportForm: FormGroup = new FormGroup({
    dateFilter: new FormControl(''),
  });
  activePage: number = 0;
  reportData = new HardcodedData();

  allReportData: any;
  searchTerm: any;
  filteredReportData: any[] = [];
  category: any[] = [];
  totalCount: number = 0;
  pageSize: number = 10;
  // pageSizeList = [10, 25, 50, 100];

 

  fromDate: any;
  toDate: any;


  active_deals: any;
  impressions: any;
  reach: any;
  clicks: any;
  deals_claimed: any;
  deals_redeemed: any;
  deals_favourite: any;
  ctr: any;
  money_spend: any;
  no_data: boolean = false;
  allCategory: any[] = [];






  constructor(private router: Router, public dialog: MatDialog, private service: ReportService, private dashboardService: DashboardService,
    private commonUtilService: CommonUtilService, private fb: FormBuilder,) {
    this.placeHolders = this.placeholders['Reports_page']
    this.apiPlaceHolders = this.placeholders['API_Messages']
    this.statusList = [
      { value: '', status: this.placeHolders['LBL_ALL'] },
      { value: 'A', status: this.placeHolders['LBL_ACTIVE'] },
      { value: 'C', status: this.placeHolders['LBL_COMPLETED']},
      { value: 'P', status: this.placeHolders['LBL_PENDING']},
      { value: 'E', status: this.placeHolders['LBL_EXPIRED']},
      { value: 'I', status: this.placeHolders['LBL_INACTIVE']},
      { value: 'D', status: this.placeHolders['LBL_DRAFT'] },
      { value: 'U', status: this.placeHolders['LBL_UPCOMING']},
      { value: 'S', status: this.placeHolders['LBL_PAUSED']},
      { value: 'R', status: this.placeHolders['LBL_REJECTED'] },
      { value: 'N', status:  this.placeHolders['LBL_NEW']  }
    ];
    let currentDate = new Date();
    let parsedDate = moment(currentDate, "YYYY-MM-DD");
    this.presentDate = parsedDate.format("MM/DD/YYYY");
    this.createReportForm();
    this.last15DaysData();
    this.defaultColDef = this.commonUtilService.getDefaultColumnDef();
    this.gridOptions.defaultColDef = this.defaultColDef;
    this.createColumnDefs();
    this.gridOptions.columnDefs = this.columnDefs;
    this.gridOptions.rowHeight = 70;


    const today = new Date();
    this.fromDate = formatDate(today, 'yyyy-MM-dd', 'en-US');
    this.toDate = formatDate(today, 'yyyy-MM-dd', 'en-US');
  }



  activeButton: string = '1D';
  setActiveButton(button: string) {
    this.activeButton = button;

    const today = new Date();
    let newFromDate: Date;

    switch (button) {
      case '1D':
        newFromDate = new Date(today);
        break;
      case '1W':
        newFromDate = new Date(today);
        newFromDate.setDate(today.getDate() - 7);
        break;
      case '1M':
        newFromDate = new Date(today);
        newFromDate.setMonth(today.getMonth() - 1);
        if (newFromDate.getDate() !== today.getDate()) {
          newFromDate.setDate(0);
        }
        break;
      case '3M':
        newFromDate = new Date(today);
        newFromDate.setMonth(today.getMonth() - 3);
        if (newFromDate.getDate() !== today.getDate()) {
          newFromDate.setDate(0);
        }
        break;
      default:
        newFromDate = new Date(today);
        break;
    }

    this.fromDate = formatDate(newFromDate, 'yyyy-MM-dd', 'en-US');
    this.toDate = formatDate(today, 'yyyy-MM-dd', 'en-US');


    this.getDealsCounts();
  }

  status = 'A';
  category_type: string = '';

  onCategoryChange(selectedValue: any) {
    this.category_type = selectedValue;
  }


  ngOnInit(): void {
   

    // this.getDealingCount();
    this.defaultColDef = {
      editable: true,
      sortable: true,
      minWidth: 100,
      filter: true,
      resizable: false,
      tooltip: false,
      width: 170,

    };
    this.createReportForm();

    this.dashboardService.getCategory().subscribe((res: any) => {
      this.allCategory = res?.body.data;
      this.allCategory.unshift({ category_id: '', category_type: 'All' });
      this.category_type = '';
    });


    this.getDealsCounts();
    setTimeout(() => {
      this.getReportList();
    }, 1000)



  }

  

  getDealsCounts() {
    this.service.getDealsCounts(this.fromDate, this.toDate).subscribe((data: any) => {
debugger
      this.active_deals = data.body.data.activeDealsCount;
      this.deals_redeemed = data.body.data.totalRedeems;
      this.ctr = data.body.data.ctr;
      this.money_spend = data.body.data.moneySpent;
      this.impressions = data.body.data.eventCounts.DEAL_IMPRESSIONS_EVENT.count;
      this.reach = data.body.data.eventCounts.DEAL_IMPRESSIONS_UNIQUE_EVENT.count;
      this.clicks = data.body.data.eventCounts.DEAL_CLICK_EVENT.count;
      this.deals_claimed = data.body.data.eventCounts.DEAL_COUPON_CODE_VIEW_EVENT.count;
      this.deals_favourite = data.body.data.eventCounts.DEAL_FAVOURITE_EVENT.count
    })
  }

  statusList = [
    { value: '', status: 'All' },
    { value: 'A', status: 'Active' },
    { value: 'C', status: 'Completed' },
    { value: 'P', status: 'Pending' },
    { value: 'E', status: 'Expired' },
    { value: 'I', status: 'Inactive' },
    { value: 'D', status: 'Draft' },
    { value: 'U', status: 'Upcoming' },
    { value: 'S', status: 'Paused' },
    { value: 'R', status: 'Rejected' },
    { value: 'N', status: 'New' }
  ];




  getReportList(page: number = 1, pageSize: number = 10) {
    const status = this.status;
    const category = this.category_type;

    this.service.getAllReportData(page, pageSize, status, category).subscribe(
      (data: any) => {
        if (data.body.data.events.length === 0 || data.body.message === 'No Deals Found') {
          this.no_data = true;
        } else {
          this.allReportData = data.body.data.events;
          this.totalCount = data.body.data.totalCount;
          this.currentPage = data.body.data.currentPage;
          this.no_data = false;
        }
      },
      (error) => {
        console.error('Error fetching report data:', error);
        this.no_data = true;
      }
    );
  }

  onPageChange(page: number): void {
    this.getReportList(page, this.pageSize);
  }

 


  createReportForm() {
    this.reportForm = this.fb.group({
      dateFilter: ['3'],
      toDate: [this.presentDate],
      fromDate: [this.past15Days],
      filterStatus: [''],
      filterbudget: [''],
      filterSpent: [''],
      pagination: ['1'],
      search: [''],
    });
  }

  getReportData(fromDate?: any, toDate?: any) {
    this.reportForm.value.fromDate = fromDate;
    this.reportForm.value.toDate = toDate;
    // this.service.getAllReportData(this.reportForm.value).subscribe(res => {
    //   if (res.body?.data) {
    //     this.allReportData = res.body?.data?.DEALS;
    //   }
    // });
  }

  filterScreen() {
    this.dialog.open(FilterReportComponent, {
      height: 'auto',
      width: '500px',
      position: {
        top: '12vh',
        left: '30vw'
      },
      autoFocus: false,
      backdropClass: 'custom-dialog-overlay',  // mat-dialog css class
      disableClose: true  // If you click outside the mat-dialog box window, it will not close. 
    });
    this.service.getCloseEvent().subscribe((res: any) => {
      let filterData = res;
      if (filterData !== null && filterData !== undefined) {
        // this.reportForm.value.categories = filterData.category;
        this.reportForm.value.filterStatus = filterData.status;
        // this.reportForm.value.filterbudget = filterData.budget;
        // this.reportForm.value.filterSpent = filterData.actualSpent;
        this.getReportData(this.reportForm.value.fromDate, this.reportForm.value.toDate);
      }

    });
  }

  radioChange() {

  }

  setFromDate(event: any) {
    let value = event.target.value;
    let parsedDate = moment(value, "YYYY-MM-DD");
    this.fromDate = parsedDate.format("MM/DD/YYYY");

  }

  setToDate(event: any) {
    let value = event.target.value;
    let parsedDate = moment(value, "YYYY-MM-DD");
    this.toDate = parsedDate.format("MM/DD/YYYY");
  }

  customOkClick() {
    // this.datePickerVisible = false;
    // this.getTableData(this.fromDate, this.toDate);    
    this.getReportData(this.fromDate, this.toDate);
  }

  customClick() {
    this.datePickerVisible = true;
  }


  lastWeekData() {
    this.datePickerVisible = false;
    const pastWeek = new Date(
      new Date().getTime() - 7 * 24 * 60 * 60 * 1000
    );
    let parsedDate = moment(pastWeek, "YYYY-MM-DD");
    this.pastWeek = parsedDate.format("MM/DD/YYYY");
    this.getReportData(this.pastWeek, this.presentDate);

  }

  last15DaysData() {
    this.datePickerVisible = false;
    const past15Days = new Date(
      new Date().getTime() - 15 * 24 * 60 * 60 * 1000
    );
    let parsedDate = moment(past15Days, "YYYY-MM-DD");
    this.past15Days = parsedDate.format("MM/DD/YYYY");
    this.getReportData(this.past15Days, this.presentDate);
  }

  lastMonthData() {
    this.datePickerVisible = false;
    const pastMonthDay = new Date(
      new Date().getTime() - 30 * 24 * 60 * 60 * 1000
    );
    let parsedDate = moment(pastMonthDay, "YYYY-MM-DD");
    this.pastMonthDay = parsedDate.format("MM/DD/YYYY");
    this.getReportData(this.pastMonthDay, this.presentDate);
  }

  last3MonthData() {
    this.datePickerVisible = false;
    const past3Month = new Date(
      new Date().getTime() - 90 * 24 * 60 * 60 * 1000
    );
    let parsedDate = moment(past3Month, "YYYY-MM-DD");
    this.past3Month = parsedDate.format("MM/DD/YYYY");
    this.getReportData(this.past3Month, this.presentDate);
  }

  onGridReady(params: any, value?: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    if (value && value !== null && value !== undefined) {
      this.paginationPageSize = value;
      this.ngOnInit();
    } else {
      this.paginationPageSize = 10;
    }
  }

  quickSearch(e: any) {
    // if (e.target.value.length >= 3 || e.target.value.length === 0) {
    this.gridApi.setQuickFilter(e.target.value);
    // }
  }

  searchClick(event: any) {
    this.gridApi.setQuickFilter(event.target.value);
  }

  createColumnDefs() {
    this.columnDefs = [];
    let header: any;

    this.frameworkComponents = {
      // toggleComponent: ToggleComponent,
      edit: ViewComponent,
      // cellRendererFramework: RouterLinkRendererComponentComponent
    };

    header = this.commonUtilService.getColumnHeader(
      "campaignName",
      "Campaign Name"
    );
    header.filterParams = this.commonUtilService.getFilterButtons();
    header.filterParams.suppressAndOrCondition = true;
    header.minWidth = 200;
    // header.wrapText= true;
    header.filterParams.filterOptions =
      this.commonUtilService.getCommaSeparatedFilter();
    this.columnDefs.push(header);

    header = this.commonUtilService.getColumnHeader(
      "campaignID",
      "Campaign ID"
    );
    header.filterParams = this.commonUtilService.getFilterButtons();
    header.filter = false;
    header.minWidth = 100;
    header.filterParams.suppressAndOrCondition = true;
    header.filterParams.filterOptions =
      this.commonUtilService.getCommaSeparatedFilter();
    this.columnDefs.push(header);

    header = this.commonUtilService.getColumnHeader(
      "startDate",
      "Start Date"
    );
    header.filterParams = this.commonUtilService.getFilterButtons();
    header.filter = false;
    header.minWidth = 70;
    header.filterParams.suppressAndOrCondition = true;
    header.filterParams.filterOptions =
      this.commonUtilService.getCommaSeparatedFilter();
    this.columnDefs.push(header);

    header = this.commonUtilService.getColumnHeader(
      "endDate",
      "End Date"
    );
    header.filterParams = this.commonUtilService.getFilterButtons();
    header.filter = false;
    header.minWidth = 70;
    header.filterParams.suppressAndOrCondition = true;
    header.filterParams.filterOptions =
      this.commonUtilService.getCommaSeparatedFilter();
    this.columnDefs.push(header);


    header = this.commonUtilService.getColumnHeader(
      "status",
      "Status"
    );
    header.cellRenderer = SwitchToggleComponent;
    header.filterParams = this.commonUtilService.getFilterButtons();
    header.filter = false;
    header.minWidth = 70;
    header.filterParams.suppressAndOrCondition = true;
    header.filterParams.filterOptions =
      this.commonUtilService.getCommaSeparatedFilter();
    this.columnDefs.push(header);

    header = this.commonUtilService.getColumnHeader(
      "budget",
      "Budget"
    );
    // header.pinned = "right";
    header.filter = false;
    header.minWidth = 90;
    header.filterParams = this.commonUtilService.getFilterButtons();
    header.filterParams.suppressAndOrCondition = true;
    header.filterParams.filterOptions =
      this.commonUtilService.getCommaSeparatedFilter();
    this.columnDefs.push(header);


    header = this.commonUtilService.getColumnHeader(
      "actualSpent",
      "Actual Spent"
    );
    header.filterParams = this.commonUtilService.getFilterButtons();
    header.filter = false;
    header.minWidth = 150;
    header.filterParams.suppressAndOrCondition = true;
    header.filterParams.filterOptions =
      this.commonUtilService.getCommaSeparatedFilter();
    this.columnDefs.push(header);

    header = this.commonUtilService.getColumnHeader(
      "",
      "Action"
    );
    header.cellRenderer = ViewComponent;
    header.cellRendererParams = {
      clicked: (field: any) => {
        this.service.setData(field);
        this.router.navigate(["/reports/detailedReport"]);
      }
    },
      header.filterParams = this.commonUtilService.getFilterButtons();
    header.filter = false;
    header.filterParams.suppressAndOrCondition = true;
    header.filterParams.filterOptions =
    this.commonUtilService.getCommaSeparatedFilter();
    this.columnDefs.push(header);
  }

  displayActivePage(activePageNumber: number) {
    this.activePage = activePageNumber;
    // this.getTableData('11/18/2022', '11/28/2023', activePageNumber);
  }


}
