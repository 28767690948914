


<div  class="image-container">
    <img [src]="dealPreviewImg" style="max-width: 100%; max-height: 100%;" class="static-image">
    
    <div *ngIf="!previewFromScreen" class="image-actions">
        <button class="btn btn-primary" (click)="skipForNow()">Skip for now</button>
        <button class="btn btn-secondary" (click)="dontShowAgain()">Don't show again</button>
    </div>
</div>