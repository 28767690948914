<ng-container>
  <c-container [fluid]="true">
    <!-- <button [cSidebarToggle]="sidebarId" cHeaderToggler class="btn" toggle="visible" style="margin-inline-start: -14px;"
      aria-label="Toggle sidebar navigation">
      <svg cIcon name="cilMenu" size="lg"></svg>
    </button> -->
    <!-- TEMP COMMENTED -->
    <c-header-nav class="ms-3">
      <!-- <div class="div-5">Quick Help</div>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/c7103198-46c5-425b-ae2f-e72bfde233fc?"
          class="img-3 px-2"
        /> -->
      <div style="font-size: larger ;
        font-weight: 500 ;
        color: #36849d ;">
        {{merchantName}}
      </div>
    </c-header-nav>

    

    <c-header-nav class="ms-auto">
      <div>
        <img src="../../../assets/images/Ship2myID_powerBY.png" style="margin-right: -20px;height: auto;width: 150px;"
          alt="Right Logo">
      </div>
      <div class="nav-item py-1">
        <div class="vr h-100 text-body text-opacity-75"></div>
      </div>
      <ng-container *ngTemplateOutlet="themeDropdown" />
      <div class="nav-item py-1">
        <div class="vr h-100 text-body text-opacity-75"></div>
      </div>
    </c-header-nav>
    <c-header-nav class="mx-0">
      <ng-container *ngTemplateOutlet="userDropdown" />
    </c-header-nav>

  </c-container>
</ng-container>


<ng-template #themeDropdown>
  <c-dropdown alignment="end" variant="nav-item">

    <!-- <button [caret]="false" cDropdownToggle aria-label="Open theme picker"> -->
    <!-- <svg cIcon [name]="icons()" size="lg"></svg> -->
    <!-- <svg [caret]="false" cDropdownToggle xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24">
        <path fill="#1f8dd1"
          d="M5.08 8h2.95c.32-1.25.78-2.45 1.38-3.56c-1.84.63-3.37 1.9-4.33 3.56m2.42 4c0-.68.06-1.34.14-2H4.26c-.16.64-.26 1.31-.26 2s.1 1.36.26 2h3.38c-.08-.66-.14-1.32-.14-2m-2.42 4a7.987 7.987 0 0 0 4.33 3.56A15.65 15.65 0 0 1 8.03 16zM12 4.04c-.83 1.2-1.48 2.53-1.91 3.96h3.82c-.43-1.43-1.08-2.76-1.91-3.96M18.92 8a8.03 8.03 0 0 0-4.33-3.56c.6 1.11 1.06 2.31 1.38 3.56zM12 19.96c.83-1.2 1.48-2.53 1.91-3.96h-3.82c.43 1.43 1.08 2.76 1.91 3.96m2.59-.4A8.03 8.03 0 0 0 18.92 16h-2.95a15.65 15.65 0 0 1-1.38 3.56M19.74 10h-3.38c.08.66.14 1.32.14 2s-.06 1.34-.14 2h3.38c.16-.64.26-1.31.26-2s-.1-1.36-.26-2M9.66 10c-.09.65-.16 1.32-.16 2s.07 1.34.16 2h4.68c.09-.66.16-1.32.16-2s-.07-1.35-.16-2z"
          opacity="0.3" />
        <path fill="#1f8dd1"
          d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2m6.93 6h-2.95a15.65 15.65 0 0 0-1.38-3.56A8.03 8.03 0 0 1 18.92 8M12 4.04c.83 1.2 1.48 2.53 1.91 3.96h-3.82c.43-1.43 1.08-2.76 1.91-3.96M4.26 14C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2s.06 1.34.14 2zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56A7.987 7.987 0 0 1 5.08 16m2.95-8H5.08a7.987 7.987 0 0 1 4.33-3.56A15.65 15.65 0 0 0 8.03 8M12 19.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96M14.34 14H9.66c-.09-.66-.16-1.32-.16-2s.07-1.35.16-2h4.68c.09.65.16 1.32.16 2s-.07 1.34-.16 2m.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95a8.03 8.03 0 0 1-4.33 3.56M16.36 14c.08-.66.14-1.32.14-2s-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2z" />
      </svg> -->
    <svg [caret]="false" cDropdownToggle xmlns="http://www.w3.org/2000/svg" width="2.5em" height="2.5em"
      viewBox="0 0 512 512">
      <path fill="#1f8dd1"
        d="M320 24H16v304h176v168h304V192H320ZM148.305 96L98.093 239.3H132l8.166-23.3H192v80H48V56h240v136h-66.668L187.7 96Zm36.317 88h-33.244L168 136.562ZM464 224v240H224V224Z" />
      <path fill="#1f8dd1"
        d="M317.432 368.48a136.761 136.761 0 0 0 10.089 14.12q-17.4 9.384-39.521 9.4v32c24.141 0 45.71-6.408 64-18.824C370.29 417.592 391.859 424 416 424v-32q-22.075 0-39.52-9.407a136.574 136.574 0 0 0 10.088-14.113A166.212 166.212 0 0 0 406.662 320H424v-32h-56v-24h-32v24h-56v32h17.338a166.212 166.212 0 0 0 20.094 48.48M373.53 320a133.013 133.013 0 0 1-14.1 31.52a104.39 104.39 0 0 1-7.43 10.448a103.546 103.546 0 0 1-6.93-9.651A132.384 132.384 0 0 1 330.466 320Z" />
    </svg>
    <!-- </button> -->
    <div cDropdownMenu>
      @for (mode of colorModes; track mode.name) {
      <button (click)="colorMode(mode.name)" cDropdownItem class="d-flex align-items-center">
        <svg cIcon class="me-2" [name]="mode.icon" size="lg"></svg>
        {{ mode.text }}
      </button>
      }
    </div>

    <!-- (click)="colorMode.set(mode.name)"
    [active]="colorMode()===mode.name"
    [routerLink]="[]" -->
  </c-dropdown>
</ng-template>

<ng-template #userDropdown>
  <c-dropdown [popperOptions]="{ placement: 'bottom-start' }" variant="nav-item">
    <button [caret]="false" cDropdownToggle class="py-0 pe-0" aria-label="Open user menu" style="
    border: none;
    background: white;
">
      <c-avatar shape="rounded-1" [size]="'md'" src="../../../assets/images/user.png" status="success"
        textColor="primary" alt="User avatar" />
    </button>
    <ul cDropdownMenu class="pt-0 w-auto">
      <li>
        <h6 style="text-transform: capitalize;" cDropdownHeader
          class="bg-body-secondary text-body-secondary fw-semibold py-2 rounded-top">
          {{userRoleType}}
        </h6>
      </li>
      <li>
        <a cDropdownItem routerLink="/companyProfile/details" style="text-transform: capitalize;">
          <img src="path_to_your_image.jpg" style="height: 25px; width: 25px;" alt="User Profile Image"
            class="profile-image" onerror="this.onerror=null;this.src='../../../assets/images/user.png';">
          {{userFullName}}
        </a>
      </li>
      <!-- <li>
        <h6 cDropdownHeader class="bg-body-secondary text-body-secondary fw-semibold py-2">
          {{placeHolders['LBL_RIGHT_SETTINGS']}}
        </h6>
      </li>
      <li></li>
      <li>
        <a cDropdownItem routerLink="/companyProfile/details">
          <svg cIcon class="me-2" name="cilUser"></svg>
          {{placeHolders['LBL_RIGHT_PROFILE']}}
        </a>
      </li>
      <li>
        <a cDropdownItem routerLink="/companyProfile">
          <svg cIcon class="me-2" name="cilSettings"></svg>
          {{placeHolders['LBL_RIGHT_SETTINGS']}}
        </a>
      </li>
      <li>
        <a cDropdownItem routerLink="/help">
          <svg cIcon class="me-2" name="cilBell"></svg>
          {{placeHolders['LBL_RIGHT_HELP']}}
        </a>
      </li> -->

      <!-- TEMP COMMENTED -->
      <li>
        <hr cDropdownDivider />
      </li>
      <!-- <li>
        <a cDropdownItem routerLink="">
          <svg cIcon class="me-2" name="cilLockLocked"></svg>
          Lock Account
        </a>
      </li> -->
      <li>
        <a cDropdownItem routerLink="">
          <svg cIcon class="me-2" name="cilAccountLogout"></svg>
          {{placeHolders['LBL_RIGHT_LOGOUT']}}
        </a>
      </li>
    </ul>
  </c-dropdown>
</ng-template>