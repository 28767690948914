<div class="header d-flex align-items-center" style="background-color: #f5f5f5; padding: 15px; border-radius: 5px;">
    <!-- Logo on the left -->
    <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/9498edba-5ab7-4231-9132-36334e2e7c6d?" alt="Logo"
        style="height: 30px; width: auto;">

    <!-- Center Text -->
    <h1 class="text-center flex-grow-1" style="margin: 0; font-size: 1.5rem; color: #024e67;margin-left: -8rem;">Frequently Asked Questions
    </h1>
</div>

<div class="container pt-2">


    <mat-card style="height: 35vh;background-color: #36849d26; border-radius: 5px;">
        <!-- <div style="margin-top: 25px; margin-left: 50px;">
            <div>
                <span style="font-size: 2rem;">Your Questions, Our Commitment to Help!</span>
            </div>
            <div *ngIf="showFAQAccordion">
                <mat-form-field style="width: 45%;" appearance="outline">
                    <mat-label>Search</mat-label>
                    <input matInput [(ngModel)]="searchQuery" placeholder="Search questions" (input)="filterFAQs()">
                </mat-form-field>
            </div>
        </div> -->
        <div
            style="display: flex; flex-direction: column; justify-content: center; align-items: center; height: 100%; margin-top: 25px;">
            <div>
                <span style="font-size: 2rem; text-align: center;">Your Questions, Our Commitment to Help!</span>
            </div>
            <div *ngIf="showFAQAccordion" style="margin-top: 15px;">
                <mat-form-field style="width: 100%;" appearance="outline">
                    <mat-label>Search</mat-label>
                    <input matInput [(ngModel)]="searchQuery" placeholder="Search questions" (input)="filterFAQs()">
                </mat-form-field>
            </div>
        </div>
    </mat-card>



    <div *ngIf="showFAQAccordion" class="pt-2">
        <!-- <div style="padding-top: 5px; padding-bottom: 5px;"> <button (click)="toggleFAQAccordion()"
            class="btn btn-primary btn-block">Back</button>
    </div> -->


        <mat-accordion>
            <div *ngFor="let faq of filteredFaqs; let i = index" style="padding-bottom: 10px;">
                <mat-expansion-panel [expanded]="expandedIndexes.includes(i)">
                    <mat-expansion-panel-header>
                        <mat-panel-title style="color: rgb(7, 78, 15);">
                            {{ faq.category }}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div *ngFor="let question of faq.questions" style="padding-bottom: 5px;">
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title style="color: rgb(2, 86, 103);">
                                    {{ question.question }}
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p>{{ question.answer }}</p>
                        </mat-expansion-panel>
                    </div>
                </mat-expansion-panel>
            </div>
        </mat-accordion>
    </div>
</div>