import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-createflashdeal',
  templateUrl: './createflashdeal.component.html',
  styleUrl: './createflashdeal.component.scss'
})
export class CreateflashdealComponent implements ICellRendererAngularComp{
  params: any;
  placeholders = environment.placeholders as { [key: string]: string };
  placeHolders:any;

  constructor() {
    this.placeHolders = this.placeholders['dashboard_page']
  }

  refresh(): boolean {
    return true;
  }

  agInit(params: any) {
    this.params = params;
  }

  view() {
    const today = new Date()
    const selectedData = {
      ...this.params.data,
      total_spent: null,
      authorizedAmount: null,
      amountAuthorizedDate:null,
      amountCapturedDate:null,
      budget: null,
      status: 'D',
      available_from:null,
      available_to:null,
      capturedAmount:null,
      paymentDone:null,
      product_id:null,
      uuid:null
    };
    return this.params.clicked(selectedData);
  }
}
