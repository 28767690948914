import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ClientSideStorageService } from '../services/client-side-storage.service';

@Injectable({
    providedIn: 'root'
})


export class RouteResolver implements Resolve<any> {
    country: any;
    macAddress: any;
    countryInitials: any;

    constructor(private http: HttpClient, private cStorage: ClientSideStorageService) {
        this.macAddress = Math.random();
        this.cStorage.set('country', JSON.stringify([]));
        this.cStorage.set('macAddress', JSON.stringify(this.macAddress));
        this.country = JSON.parse(this.cStorage.get("country"));
        this.macAddress = JSON.parse(this.cStorage.get("macAddress"));
    }

   
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        const { endpoint, service } = route.data;
        // Extract endpoint or service method from route data
        this.countryInitials = route.queryParams['id'] ? route.queryParams['id'] : 'D';

        if (endpoint) {
            // Call API endpoint
            // const translations = localStorage.getItem('userLocale');

            if(this.countryInitials === 'C'){
               let updatedendpoint = endpoint +'?locale=FR' 
               return this.fetchData(updatedendpoint);
            }
            // else if(this.countryInitials === 'D') {
            //     let updatedendpoint = endpoint +'?locale=EN'
            //     return this.fetchData(updatedendpoint);
            // }
            else{
                return this.fetchData(endpoint);
            }
           
        } else if (service && typeof service === 'function') {
            // Call service method
            return service();
        } else {
            throw new Error('Invalid resolver configuration. Specify endpoint or service method in route data.');
        }
    }

    private fetchData(endpoint: string): Observable<any> {
        // Implement logic to fetch data from API endpoint
        const httpOptions = {
            // 'userName': 'satheshjkv@gmail.com',
            'macAddress': this.macAddress,
            'country': this.country
        };
        return this.http.get(endpoint, { headers: httpOptions,withCredentials:true });; // Replace with actual API call using HttpClient
    }
}

