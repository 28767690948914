import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ColumnApi, GridApi, GridOptions } from "ag-grid-community";
import { CommonUtilService } from 'src/app/services/common-util.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ReportService } from 'src/app/services/report.service';
import { Chart, ChartData, registerables } from 'chart.js';
import { environment } from 'src/environments/environment';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

Chart.register(...registerables);




@Component({
  selector: 'app-detailed-report',
  templateUrl: './detailed-report.component.html',
  styleUrl: './detailed-report.component.scss'
})
export class DetailedReportComponent {
  placeholders = environment.placeholders as { [key: string]: string };
  placeHolders:any;
  apiPlaceHolders:any;
  [x: string]: any;
  gridOptions = <GridOptions>{};
  gridApi: any;
  gridColumnApi: any;
  frameworkComponents: any;
  columnDefs: any;
  header: any;
  id: any;
  defaultColDef = {};
  paginationPageSize: any;
  // currentPage: any;
  isPageChanged: any;
  types = [10, 20, 30];
  paramsSelectRecord = {
    type: "gridReady",
    api: GridApi,
    columnApi: ColumnApi,
  };
  minRowHeight = 25;
  data: any = [];
  loanFilter: any;
  reportData: any;
  detailedReportForm: FormGroup = new FormGroup({
    dateFilter: new FormControl(''),
    totalCampaign: new FormControl(''),
    totalBudget: new FormControl(''),
    actualSpent: new FormControl('')
  });

  report_id: any;
  budget: any;
  spent: any;
  budget_percentage: any;
  spent_percentage: any;
  image: any;
  name: any;
  start_date: any;
  end_date: any;
  description: any;
  status: any;
  errorMessage: any;
  dealDetails: any;
  reach: any;
  ctr: any;
  deal_claimed: any;
  redeem: any;
  web_view: any;







  constructor(private router: Router, private service: ReportService,
    private commonUtilService: CommonUtilService, private fb: FormBuilder, private _activateRoute: ActivatedRoute) {
    this.placeHolders = this.placeholders['Reports_page']
    this.apiPlaceHolders = this.placeholders['API_Messages']
    this.reportData = this.service.getData();
    this.reportData = this.reportData.source._value;
    this.createForm();
    this.defaultColDef = this.commonUtilService.getDefaultColumnDef();
    this.gridOptions.defaultColDef = this.defaultColDef;
    this.createColumnDefs();
    this.gridOptions.columnDefs = this.columnDefs;
    this.gridOptions.rowHeight = 70;

    this.report_id = this._activateRoute.snapshot.paramMap.get('id');


  }




  dropdownOpen = false;

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }
  dropdownOpen_ = false;

  toggleDropdown_() {
    this.dropdownOpen_ = !this.dropdownOpen_;
  }

  dropdownEvent = false;
  toggleDropdownEvent() {
    this.dropdownEvent = !this.dropdownEvent;
  }

  ngOnInit(): void {
    this.defaultColDef = {
      editable: true,
      sortable: true,
      minWidth: 100,
      filter: true,
      resizable: false,
      tooltip: false,
      width: 170,
    };

    this.getDealDetails();


  }


  getDealDetails() {
    this.service.getDealDetails(this.report_id).subscribe((data: any) => {
      this.dealDetails = data.data;
      this.image = this.dealDetails.image;
      this.name = this.dealDetails.name;
      this.start_date = this.dealDetails.start_date;
      this.end_date = this.dealDetails.end_date;
      this.description = this.dealDetails.description;
      this.status = this.dealDetails.status;
      this.budget = data.data.budget;
      this.spent = data.data.spent;
      this.budget_percentage = data.data.percentageAvailable;
      this.spent_percentage = data.data.percentageSpent;
      this.getReportById();
    })
  }



  apiResponse: any;

  @ViewChild('chartCanvas') chartCanvas!: ElementRef<HTMLCanvasElement>;
  chart!: Chart;

  ngAfterViewInit() {
    this.getDetailsChart();
    this.getGenderChart();
    this.getAgeChart();

  }

  selectedEvents: string[] = ['Reach'];

  getDetailsChart() {
    this.service.getDealDetailsChart(this.report_id).subscribe(
      (data: any) => {
        this.apiResponse = data.data;
        this.initializeChart();
      },
      (error:any) => {
        console.error('Error fetching chart data:', error);
      }
    );
  }

  onEventToggle(event: string) {
    const index = this.selectedEvents.indexOf(event);
    this.dropdownEvent = false;
    if (index > -1) {
      this.selectedEvents.splice(index, 1);
    } else {
      this.selectedEvents.push(event);
    }
    this.updateChartData();
  }

  getSelectedEventsText(): string {
    return this.selectedEvents.length > 0 ? this.selectedEvents.join(', ') : 'Select Events';
  }

  initializeChart() {
    const ctx = this.chartCanvas.nativeElement.getContext('2d');

    if (ctx) {
      this.chart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: [],
          datasets: []
        },
        options: {
          responsive: true,
          scales: {
            x: {
              type: 'category',

            },
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: 'Impressions'
              }
            }
          },
          plugins: {
            tooltip: {
              callbacks: {
                label: (context: any) => {
                  return context.dataset.label + ': ' + context.raw;
                }
              }
            },
            legend: {
              position: 'bottom'
            }
          }
        }
      });

      this.updateChartData();
    } else {
      console.error('Failed to get 2D context');
    }
  }

  updateChartData() {
    const eventKeyMapping: any = {
      'Clicks': 'DEAL_CLICK_EVENT',
      'Favorites': 'DEAL_FAVOURITE_EVENT',
      'CouponcodeView': 'DEAL_COUPON_CODE_VIEW_EVENT',
      'WebView': 'DEAL_URL_LINK_CLICK_EVENT',
      'Reach': 'DEAL_IMPRESSIONS_UNIQUE_EVENT'
    };

    const eventColors: any = {
      'Clicks': '#FF4500',
      'Favorites': '#FFD700',
      'CouponcodeView': '#32CD32',
      'WebView': '#8A2BE2',
      'Reach': 'rgb(0, 227, 150)'
    };

    const xaxisdate: string[] = this.apiResponse.dateRange.map((date: string) => {
      const [year, month, day] = date.split('-');
      const dateObj = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
      return dateObj.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric'
      }).replace(/ /g, ' ');
    });

   // const datasets: any[] = [];

    const datasets: any[] = [
      {
        label: 'Impressions',
        data: this.apiResponse.dateRange.map((date: string) => this.apiResponse.eventsData[date]['DEAL_IMPRESSIONS_EVENT']),
        backgroundColor: 'rgba(0, 143, 251, 0.85)',
        borderColor: 'rgba(0, 143, 251, 1)',
        borderWidth: 0.5,
        type: 'bar',
        order: 1
      }
    ];

    this.selectedEvents.forEach(event => {
      const eventKey = eventKeyMapping[event];
      const lineSeries = {
        label: event,
        data: this.apiResponse.dateRange.map((date: string) => this.apiResponse.eventsData[date][eventKey]),
        backgroundColor: eventColors[event],
        borderColor: eventColors[event],
        borderWidth: 5,
        type: 'line',
        fill: false,
        order: 0
      };
      datasets.push(lineSeries);
    });

    this.chart.data.labels = xaxisdate;
    this.chart.data.datasets = datasets;
    this.chart.update();
  }



  selectedEvent = 'Impressions';
  genderResponse: any;
  allGenderData: any;
  genderChartData: any;
  @ViewChild('chartGender') chartGender!: ElementRef;
  chart_GENDER!: any;

  genderColors: { [key: string]: string } = {
    'Male': '#42A5F5',
    'Female': '#FF7043',
    'Others': '#66BB6A'
  };



  getGenderChart() {
    this.service.getDealDetailsGenderChart(this.report_id).subscribe((data: any) => {
      if (data && data.data) {
        this.genderResponse = data.data;
        this.allGenderData = this.transformGenderResponse(this.genderResponse);
        console.log(this.allGenderData);
        this.updateGenderChart(this.selectedEvent);
      }
    });
  }

  updateGenderChart(event: string) {
    const genderGroups = Object.keys(this.genderResponse);
    const seriesData = genderGroups.map(genderGroup => this.genderResponse[genderGroup][event]);
    console.log(seriesData);

    const colors = genderGroups.map(gender => this.genderColors[gender] || '#CCCCCC');
    const canvas = this.chartGender.nativeElement;
    const ctx = canvas.getContext('2d');

    if (ctx) {

      if (ctx) {
   
        if (this.chart_GENDER) {
          this.chart_GENDER.destroy();
        }


        this.chart_GENDER = new Chart(ctx, {
          type: 'doughnut',
          data: {
            labels: genderGroups,
            datasets: [{
              label: event,
              data: seriesData,
              backgroundColor: colors,


            }]
          },
          options: {
            responsive: true,
           
            plugins: {

              tooltip: {
                callbacks: {
                  label: (context: any) => {
                    return `${context.label}: ${context.raw}`;
                  }
                }
              },
              legend: {
                position: 'bottom'
              }
            }
          }
        });
      }

    }
  }


  selectEvent(event: string) {
    this.selectedEvent = event;
    this.dropdownOpen = false;
    this.updateGenderChart(this.selectedEvent);
  }

  transformGenderResponse(response: any) {
    return Object.keys(response).map(key => ({
      gender: key,
      impressions: response[key].Impressions,
      clicks: response[key].Clicks,
      favorites: response[key].Favorites,
      dealsClaimed: response[key].DealsClaimed,
      reach: response[key].Reach,
      webView: response[key].WebView
    }));
  }

  selectedEvent_ = 'Impressions';
  ageResponse: any;
  allAgeData: any;
  @ViewChild('chartAge') chartAge!: ElementRef;
  chart_AGE!: any;

  getAgeChart() {
    this.service.getDealDetailsAgeChart(this.report_id).subscribe((data: any) => {
      if (data && data.data) {
        this.ageResponse = data.data;
        this.allAgeData = this.transformageResponse(this.ageResponse);
        this.updateAgeChart(this.selectedEvent_);
      }
    });
  }

  updateAgeChart(event: string) {
    const ageGroups = Object.keys(this.ageResponse);
    const seriesData = ageGroups.map(ageGroup => this.ageResponse[ageGroup][event]);

    const canvas = this.chartAge.nativeElement;
    const ctx = canvas.getContext('2d');

    if (ctx) {
      
      if (this.chart_AGE) {
        this.chart_AGE.destroy();
      }

      
      this.chart_AGE = new Chart(ctx, {
        type: 'doughnut',
        data: {
          labels: ageGroups,
          datasets: [{
            label: event,
            data: seriesData,
            backgroundColor: ['#42A5F5', '#FF7043', '#66BB6A', '#FFA726', '#8E24AA', '#26C6DA'],
            borderColor: '#FFFFFF',
            borderWidth: 1
          }]
        },
        options: {
          responsive: true,

          plugins: {

            tooltip: {
              callbacks: {
                label: (context: any) => {
                  return `${context.label}: ${context.raw}`;
                }
              }
            },
            legend: {
              position: 'bottom'
            }
          }
        }
      });
    }
  }

  selectEvent_(event: string) {
    this.selectedEvent_ = event;
    this.dropdownOpen_ = false;
    this.updateAgeChart(this.selectedEvent_);
  }

  transformageResponse(response: any) {
    return Object.keys(response).map(key => ({
      age: key,
      impressions: response[key].Impressions,
      clicks: response[key].Clicks,
      favorites: response[key].Favorites,
      dealsClaimed: response[key].DealsClaimed,
      reach: response[key].Reach,
      webView: response[key].WebView,
    }));
  }



  getImageUrl(): string {
    return `${environment.assetsUrl}/assets/${this.image}`;
  }

  eventCounts: any;
  eventCountFlags: any;
  impression: any;
  impressionFlag: any;
  deal_click: any;
  dealClickFlag: any;
  coupens: any;
  coupensClick: any;
  favorites: any;
  favoriteClick: any;
  website: any;
  websiteClick: any;
  ctr_progress: any;

  getReportById() {
    this.service.getReportByID(this.report_id).subscribe((data: any) => {
      this.eventCounts = data.body.data.eventCounts;
      this.ctr = this.eventCounts.CTR;
      this.ctr_progress = this.eventCounts.CTR_progress;
      console.log(this.ctr_progress);
      this.deal_claimed = this.eventCounts.DEAL_COUPON_CODE_VIEW_EVENT;
      this.impression = this.eventCounts.DEAL_IMPRESSIONS_EVENT;
      this.reach = this.eventCounts.DEAL_IMPRESSIONS_UNIQUE_EVENT;
      this.deal_click = this.eventCounts.DEAL_CLICK_EVENT;
      this.favorites = this.eventCounts.DEAL_FAVOURITE_EVENT;
      this.redeem = this.eventCounts.DEALS_REDEEMED;
      this.web_view = this.eventCounts.DEAL_URL_LINK_CLICK_EVENT;


    });
  }






  userDeta: any;
  no_data: boolean = false;

  totalCount: number = 0;
  pageSize: number = 5;


  @ViewChild('content', { static: true }) content!: ElementRef;
  @ViewChild('eventChart') eventChart!: ElementRef;

  originalSelectedEvents: string[] = [];
  isGeneratingPdf = false;

  async makePdf() {
    this.isGeneratingPdf = true;

    const specificEvents = ['Reach', 'Clicks', 'Favorites', 'CouponcodeView', 'WebView'];
    this.originalSelectedEvents = [...this.selectedEvents];
    const eventCombinations = specificEvents
      .map(event => [event]);
    // eventCombinations.unshift(['Impressions', 'Reach']); 

    const pdf = new jsPDF('p', 'mm', 'a4');
    const pageHeight = pdf.internal.pageSize.getHeight();
    const pageWidth = pdf.internal.pageSize.getWidth();
    let currentY = 0;

    const addContentToPdf = async (element: HTMLElement) => {
      const canvasData = await html2canvas(element, { scrollY: -window.scrollY });
      const imgData = canvasData.toDataURL('image/png');
      const imgProps = pdf.getImageProperties(imgData);
      const imgWidth = pdf.internal.pageSize.getWidth();
      const imgHeight = (imgProps.height * imgWidth) / imgProps.width;
      if (currentY + imgHeight > pageHeight) {
        pdf.addPage();
        currentY = 0;
        pdf.setFillColor(235, 237, 239);
        pdf.rect(0, 0, pageWidth, pageHeight, 'F');
      }

      pdf.addImage(imgData, 'PNG', 0, currentY, imgWidth, imgHeight);
      currentY += imgHeight;
    };

    const createAndAddCharts = async () => {
      for (const combination of eventCombinations) {
        this.selectedEvents = combination;
        this.updateChartData();

        await new Promise(resolve => setTimeout(resolve, 1000));

        const eventChartElement = this.eventChart.nativeElement;

        this.chart.update();
        await addContentToPdf(eventChartElement);
      }
    };

    await addContentToPdf(this.content.nativeElement);
    await createAndAddCharts();
    const genderChartElement = document.querySelector('#chart_gender') as HTMLElement;
    await addContentToPdf(genderChartElement);
    const ageChartElement = document.querySelector('#chart_age') as HTMLElement;
    await addContentToPdf(ageChartElement);
    pdf.save('reports_details.pdf');
    this.selectedEvents = this.originalSelectedEvents;
    this.updateChartData();
    this.isGeneratingPdf = false;
  }





  createForm() {
    let res = this.reportData;
    this.detailedReportForm = this.fb.group({
      campaignName: [res.campaignName],
      budget: [res.budget],
      startDate: [res.startDate],
      transactions: [res.totalTransactions],
      sales: [res.totalSales],
      status: [res.status],
      fees: [res.transactionFees]
    });
  }

  createColumnDefs() {
    this.columnDefs = [];
    let header: any;

    header = this.commonUtilService.getColumnHeader(
      "title",
      "Title"
    );
    header.filterParams = this.commonUtilService.getFilterButtons();
    header.filterParams.suppressAndOrCondition = true;
    header.minWidth = 250;
    // header.wrapText= true;
    header.filterParams.filterOptions =
      this.commonUtilService.getCommaSeparatedFilter();
    this.columnDefs.push(header);

    header = this.commonUtilService.getColumnHeader(
      "counts",
      "No. of Counts"
    );
    header.filterParams = this.commonUtilService.getFilterButtons();
    header.filter = false;
    header.minWidth = 120;
    header.filterParams.suppressAndOrCondition = true;
    header.filterParams.filterOptions =
      this.commonUtilService.getCommaSeparatedFilter();
    this.columnDefs.push(header);

    header = this.commonUtilService.getColumnHeader(
      "costs",
      "Cost"
    );
    header.filterParams = this.commonUtilService.getFilterButtons();
    header.filter = false;
    header.minWidth = 100;
    header.filterParams.suppressAndOrCondition = true;
    header.filterParams.filterOptions =
      this.commonUtilService.getCommaSeparatedFilter();
    this.columnDefs.push(header);

    header = this.commonUtilService.getColumnHeader(
      "total",
      "Total"
    );
    // header.pinned = "right";
    header.filter = false;
    header.minWidth = 90;
    header.filterParams = this.commonUtilService.getFilterButtons();
    header.filterParams.suppressAndOrCondition = true;
    header.filterParams.filterOptions =
      this.commonUtilService.getCommaSeparatedFilter();
    this.columnDefs.push(header);
  }

  onGridReady(params: any, value?: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    if (value && value !== null && value !== undefined) {
      this.paginationPageSize = value;
      this.ngOnInit();
    } else {
      this.paginationPageSize = 10;
    }
  }


  viewCampaign() {
    this.router.navigate(["/campaign"]);
  }

}
