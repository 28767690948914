
export const coteivoryConfig = {
    logoUrl: 'assets/images/coteivory_head.png',
    postlogo:'assets/images/zimpost-logo.png',
    language: 'en',
    currency: 'P',
    shortLogo:'assets/images/shortCIlogo.png',
    country:'Cote De Ivory',
    favicon:'assets/cropped-ship2myid-icon-32x32.png',
    isPayment:false,
    loginPostlogo:'assets/images/CIlogoLogin.jpg',
    loginCountrylogo:'assets/images/coteivory.png'
  };