import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ClientSideStorageService } from './client-side-storage.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RetargetingService {
  userId: any;
  private sharedData: any;

  constructor(private http: HttpClient, private cStorage: ClientSideStorageService) {
    this.userId = JSON.parse(this.cStorage.get("userId"));
  }



  setData(data: any) {
    this.sharedData = data;
  }

  getData() {
    return this.sharedData;
  }

  clearData() {
    this.sharedData = null;
  }

  getFavOrClaimedDeals(data: any, brandId: any): Observable<any> {
    this.userId = JSON.parse(this.cStorage.get("userId"));
    let payload: any = {
      keywords: []
    }
    if (data.keywords?.length === 0) {
      payload.keywords = [];
    } else {
      payload.keywords = data.keywords;
    }

    let url = environment.authUrl + 'orchestration-service/admin/deal/favorited-or-claimed?' + 'userId=' + this.userId + '&brandId=' + brandId + '&pageNo=' + data.pagination + '&pageSize=10' + '&status=' + data.filterStatus;
    return this.http.post(url, payload, { observe: 'response' });
  }

  getDealMsgHistory(data: any, brandId: any): Observable<any> {
    const url = environment.authUrl + 'orchestration-service/admin/deal/favorite/message' + '?brandId=' + brandId + '&pageNo=' + data.pagination + '&pageSize=10' + '&dealId=' + data.dealId;
    return this.http.get(url, { observe: 'response' });
  }

  addMessage(data: any): Observable<any> {
    let url = environment.authUrl + 'orchestration-service/admin/deal/message/pay-and-send'  +'?gender=' + data.gender+'&ageRange=' + data.ageRange + '&city=' + data.city + '&state=' + data.state + '&country=' + data.country;
    return this.http.post(url, data, { observe: 'response' });
  }

  // orchestration-service/admin/deal/message/add

  

  getFilteredProspects(data: any, brandId: any): Observable<any> {
    const url = environment.authUrl + 'orchestration-service/admin/deal/filtered-prospects-for-deal' + '?userId=' + data.userId + '&brandId=' + brandId + '&dealId=' + data.dealId + '&gender=' + data.gender
      + '&ageRange=' + data.ageRange + '&city=' + data.city + '&state=' + data.state + '&country=' + data.country;
    return this.http.get(url, { observe: 'response' });
  }
}
