import { Dialog } from '@angular/cdk/dialog';
import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-paymentsummary',
  templateUrl: './paymentsummary.component.html',
  styleUrl: './paymentsummary.component.scss'
})
export class PaymentsummaryComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,private dialog: MatDialog) {}

  @Input() billAmount!: number;
  @Input() billCurrency!: string;
  @Input() billDescription!: string;
  @Input() brandId!: string;
  @Input() dealId!: string;
  @Input() postOfficeId!: string;
  @Input() deal_name!: string;
  @Input() type!: string;
  @Input() deal_uuid!: string;
  @Input() deal_budget!: number;
  @Input() startDate!: string;
  @Input() endDate!: string;
  @Input() imagePreview!: string | null;
  @Input() brandimage!: string;
  @Input() useremail!:string;
  @Input() userFullName!:string;
  @Input() brandname!:string;
  @Input() msgdata!:any;



ngOninit(){


}

  getFormattedTitle(): string {
    // Implement your formatting logic here
    return "Formatted Deal Title";
  }
  
  back() {
    // this.paymentInitiate = !this.paymentInitiate;
  
      this.dialog.closeAll();

  }

  showBackButton: boolean = true;

  onShowCheckoutChanged(showCheckout: boolean) {
    this.showBackButton = showCheckout; // Update the back button visibility
  }
}
