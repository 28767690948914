<mat-card style="height: 40vh;background-color: #36849d26; border-radius: 5px;">
    <div style="margin-top: 65px; margin-left: 50px;">
        <div>
            <span style="font-size: 2rem;">Your Questions, Our Commitment to Help!</span>
        </div>
        <div *ngIf="showFAQAccordion">
            <mat-form-field style="width: 45%;" appearance="outline">
                <mat-label>Search</mat-label>
                <input matInput [(ngModel)]="searchQuery" placeholder="Search questions" (input)="filterFAQs()">
            </mat-form-field>
        </div>
    </div>
</mat-card>

<div *ngIf="!showFAQAccordion">
    <div class="row" style="padding: 10px;">
        <div class="col-4" style=" padding: 5px;">
            <mat-card style="height: 30vh;background-color: #36849d26;">
                <div class="tile-header">
                    FAQs
                </div>
                <div style="padding-top: 25px;text-align: center;cursor: pointer;" (click)="toggleFAQAccordion()">
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" width="2.2em" height="2.2em" viewBox="0 0 26 26">
                        <path fill="#306e33"
                            d="M13 0c-1.7 0-3 1.3-3 3v6c0 1.7 1.3 3 3 3h6l4 4v-4c1.7 0 3-1.3 3-3V3c0-1.7-1.3-3-3-3zm4.188 3h1.718l1.688 6h-1.5l-.407-1.5h-1.5L16.813 9H15.5zM18 4c-.1.4-.212.888-.313 1.188l-.28 1.312h1.187l-.282-1.313C18.113 4.888 18 4.4 18 4M3 10c-1.7 0-3 1.3-3 3v6c0 1.7 1.3 3 3 3v4l4-4h6c1.7 0 3-1.3 3-3v-6h-3c-1.9 0-3.406-1.3-3.906-3zm4.594 2.906c1.7 0 2.5 1.4 2.5 3c0 1.4-.481 2.288-1.281 2.688c.4.2.874.306 1.374.406l-.374 1c-.7-.2-1.426-.512-2.126-.813c-.1-.1-.275-.093-.375-.093C6.112 18.994 5 18 5 16c0-1.7.994-3.094 2.594-3.094m0 1.094c-.8 0-1.188.9-1.188 2c0 1.2.388 2 1.188 2s1.218-.9 1.218-2s-.418-2-1.218-2" />
                    </svg> -->
                    <img src="../../../assets/images/FAQIcon.png" style="width: 4em; height: 4em;" />
                </div>
                <span style="text-align: center;font-size: small;padding-left: 30px;
    padding-right: 30px;">Find quick answers to common questions and learn how
                    to make the most of our platform.</span>
                <!-- <span style="padding-top: 25px;text-align: center;cursor: pointer;font-family: cursive;
    font-size: x-large;" (click)="toggleFAQAccordion()"> FAQs</span> -->

            </mat-card>
        </div>
        <div class="col-4" style=" padding: 5px;">
            <mat-card style="height: 30vh;background-color: #36849d26;">
                <div class="tile-header">
                    Instructional Videos
                </div>

                <a href="https://www.youtube.com/channel/UC6NFcqjGiYbn6phsHhuU5bQ" target="_blank"
                    style="padding-top: 25px;text-align: center;"><img alt="Youtube" loading="lazy" decoding="async"
                        data-nimg="1" class="h-7 w-7" src="../../../assets/images/youtube.png"
                        style="color: transparent;height: 4em;width: 4em;"></a>
                <span style="text-align: center;font-size: small;padding-left: 30px;
    padding-right: 30px;">Watch step-by-step video guides to help you easily navigate and use our features</span>

            </mat-card>
        </div>
        <div class="col-4" style=" padding: 5px;">
            <mat-card style="height: 30vh;background-color: #36849d26;">
                <div class="tile-header">
                    View Your Tickets
                </div>
                <div style="padding-top: 25px;text-align: center;">
                    <svg xmlns="http://www.w3.org/2000/svg" width="4em" height="4em" viewBox="0 0 24 24">
                        <g fill="none">
                            <path fill="url(#tokenBrandedChat0)"
                                d="M12.28 19.229q1.056-.072 2.084-.336a5.8 5.8 0 0 0 2.602.168a1 1 0 0 1 .104-.008c.31 0 .717.178 1.31.553v-.616a.6.6 0 0 1 .311-.525a4.7 4.7 0 0 0 .717-.492c.864-.723 1.352-1.686 1.352-2.706c0-.338-.054-.673-.159-.993c.26-.48.472-.986.627-1.51c.5.74.77 1.61.772 2.503c0 1.386-.654 2.68-1.785 3.625a6 6 0 0 1-.595.436v1.442c0 .496-.58.78-.989.486a15 15 0 0 0-1.2-.8a3 3 0 0 0-.369-.184q-.51.075-1.038.076c-1.412 0-2.717-.418-3.744-1.119m-7.466-2.884C3.03 14.854 2 12.818 2 10.64c0-4.454 4.258-8.014 9.457-8.014s9.458 3.56 9.458 8.013c0 4.455-4.259 8.014-9.458 8.014q-.877 0-1.728-.133c-.245.057-1.224.631-2.635 1.648c-.51.369-1.236.013-1.236-.608V17.1a9 9 0 0 1-1.044-.754m4.95.658q.063 0 .13.01a9.5 9.5 0 0 0 1.563.128c4.392 0 7.907-2.939 7.907-6.502s-3.515-6.501-7.907-6.501c-4.39 0-7.907 2.939-7.907 6.501c0 1.723.821 3.345 2.273 4.559q.55.458 1.196.821c.241.134.39.385.39.655v1.419c1.116-.74 1.85-1.09 2.354-1.09" />
                            <path fill="url(#tokenBrandedChat1)"
                                d="M7.625 12a1.25 1.25 0 1 0 0-2.5a1.25 1.25 0 0 0 0 2.5" />
                            <path fill="url(#tokenBrandedChat2)"
                                d="M11.687 12a1.25 1.25 0 1 0 0-2.5a1.25 1.25 0 0 0 0 2.5" />
                            <path fill="url(#tokenBrandedChat3)"
                                d="M15.75 12.001a1.25 1.25 0 1 0 0-2.5a1.25 1.25 0 0 0 0 2.5" />
                            <defs>
                                <linearGradient id="tokenBrandedChat0" x1="12" x2="12" y1="2.625" y2="21.375"
                                    gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#286a10" />
                                    <stop offset="1" stop-color="#45bf6a" />
                                </linearGradient>
                                <linearGradient id="tokenBrandedChat1" x1="12" x2="12" y1="2.625" y2="21.375"
                                    gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#286a10" />
                                    <stop offset="1" stop-color="#45bf6a" />
                                </linearGradient>
                                <linearGradient id="tokenBrandedChat2" x1="12" x2="12" y1="2.625" y2="21.375"
                                    gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#286a10" />
                                    <stop offset="1" stop-color="#45bf6a" />
                                </linearGradient>
                                <linearGradient id="tokenBrandedChat3" x1="12" x2="12" y1="2.625" y2="21.375"
                                    gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#286a10" />
                                    <stop offset="1" stop-color="#45bf6a" />
                                </linearGradient>
                            </defs>
                        </g>
                    </svg>
                </div>

                <span style="text-align: center;font-size: small;padding-left: 30px;
    padding-right: 30px;">Track the progress of your support requests and stay updated in real time</span>

                <span style="text-align: center;font-size: small;padding-left: 30px;
    padding-right: 30px;color:green; margin-top: 5px;">Coming soon...</span>
            </mat-card>
        </div>
    </div>
</div>

<div *ngIf="showFAQAccordion">
    <div style="padding-top: 5px; padding-bottom: 5px;"> <button (click)="toggleFAQAccordion()"
            class="btn btn-primary btn-block">Back</button>
    </div>


    <mat-accordion>
        <div *ngFor="let faq of filteredFaqs; let i = index" style="padding-bottom: 10px;">
            <mat-expansion-panel [expanded]="expandedIndexes.includes(i)">
                <mat-expansion-panel-header>
                    <mat-panel-title style="color: rgb(7, 78, 15);">
                        {{ faq.category }}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div *ngFor="let question of faq.questions" style="padding-bottom: 5px;">
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title style="color: rgb(2, 86, 103);">
                                {{ question.question }}
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <p>{{ question.answer }}</p>
                    </mat-expansion-panel>
                </div>
            </mat-expansion-panel>
        </div>
    </mat-accordion>
</div>