
export const zimConfig = {
    logoUrl: 'assets/images/zim-head.png',
    postlogo:'assets/images/zimpost-logo.png',
    language: 'en',
    currency: 'P',
    shortLogo:'assets/images/zimemblem.png',
    country:'Zimbabwe',
    favicon:'assets/cropped-ship2myid-icon-32x32.png',
    isPayment:false,
    loginPostlogo:'assets/images/ship2myidlogo_Sq.png',
    loginCountrylogo:'assets/images/zimpost-logo.png'
  };