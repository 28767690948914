import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogComponent } from '../../app/views/sharedComponent/confirmation-dialog/confirmation-dialog.component';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationDialogService {
  placeholders = environment.placeholders as { [key: string]: string };
  placeHolders: any;
  constructor(private modalService: NgbModal) {
    this.placeHolders = this.placeholders['Registration_page']
  }

  public confirm(
    title: string,
    message: string,
    data?: any,
    btnOkText: string = this.placeHolders['LBL_YES'],
    btnCancelText: string = this.placeHolders['LBL_EDIT'],
    dialogSize: 'sm' | 'md' | 'lg' = 'md'): Promise<boolean> {
    const modalRef = this.modalService.open(ConfirmationDialogComponent, { size: dialogSize });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.data = data;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;
    return modalRef.result;
  }

  public navigateConfirm(
    title: string,
    message: string,
    btnOkText: string = 'Save & Proceed',
    btnCancelText: string = 'Proceed without Saving',
    dialogSize: 'sm' | 'md' | 'lg' = 'md'): Promise<boolean> {
    const modalRef = this.modalService.open(ConfirmationDialogComponent, { size: dialogSize });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;

    return modalRef.result;
  }
}
